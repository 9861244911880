import React from 'react';
import { createRoot } from 'react-dom/client';
import providerHOC from '../../utils/providerHOC/providerHOC';
import store from '../headerStore';
import miniCartContainer from './miniCartContainer';

import { MINI_CART_ROOT_ELEMENT } from './miniCartConstants';

const MiniCartRoot = providerHOC(store)(miniCartContainer);
createRoot(MINI_CART_ROOT_ELEMENT).render(<MiniCartRoot />);
