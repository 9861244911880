import { SESSION_STORAGE } from 'app/utils/localStorage/localStorageConstants';
import { popStorageData } from 'app/utils/localStorage/localStorage';
import { CART_VALIDATION_FLAG } from './cartConstants';

const { cartTotalItemsStrategy } = window.inlineGlobalConfig;

export const removeCartBanner = () => {
  const banners = document.querySelectorAll('.cart__media-banner');
  [...banners].forEach(banner => {
    banner.parentNode.removeChild(banner);
  });
};

export const cartTotalItems = json => {
  switch (cartTotalItemsStrategy) {
    case 'sku':
      return json.totalItems;
    default:
      return json.entries.length || 0;
  }
};

export function isCartValidationFlagSet() {
  return !!popStorageData(CART_VALIDATION_FLAG, SESSION_STORAGE);
}
