import React from 'react';
import { createRoot } from 'react-dom/client';
import providerHOC from '../../utils/providerHOC/providerHOC';
import store from '../headerStore';
import MiniWishlistContainer from './miniWishlistContainer';

import { MINI_WISHLIST_ROOT_ELEMENT } from './miniWishlistConstants';

const MiniWishlistRoot = providerHOC(store)(MiniWishlistContainer);
createRoot(MINI_WISHLIST_ROOT_ELEMENT).render(<MiniWishlistRoot />);
