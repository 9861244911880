import { useState, useRef } from 'react';
import { HEADER_MINI_CLOSE_ALL } from '../../headerConstants';
import { closeMenu, closeSearch } from '../../../cmscomponents/header/header';

const headerBackdrop = document.querySelector('.header__backdrop');
const backdropShowClass = 'header__backdrop--animate';

export const useAccountMenu = () => {
  const [isHovered, setIsHovered] = useState(false);
  const timeoutIdRef = useRef(null);

  const handleMouseEnter = event => {
    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current);
    }

    headerBackdrop.classList.add(backdropShowClass);
    closeSearch(event);
    closeMenu();

    document.dispatchEvent(new Event(HEADER_MINI_CLOSE_ALL));

    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    timeoutIdRef.current = setTimeout(() => {
      headerBackdrop.classList.remove(backdropShowClass);
      setIsHovered(false);
    }, 300);
  };

  const cancelHideDropdown = () => {
    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current);
    }
  };

  headerBackdrop.addEventListener('click', handleMouseLeave);

  return { isHovered, handleMouseEnter, handleMouseLeave, cancelHideDropdown };
};
