import { replaceSpecialChars } from './gtmAnalyticsUtils';

export function dlTrackSearchSubmit() {
  window.dataLayer.push({
    event: 'visitor interaction',
    interaction_name: 'perform search',
    search_type: 'text search',
  });
}

export function dlTrackNoSearchSuggestions(searchTerm) {
  window.dataLayer.push({
    event: 'visitor interaction',
    interaction_name: 'perform search',
    search_term: replaceSpecialChars(searchTerm),
    search_type: 'no results',
  });
}

export function dlTrackPredictiveSearch(suggestion) {
  const currentPagePath = window.location.pathname.split('/');
  const startPathWith = 3;
  const searchType =
    suggestion.type === 'PRODUCT_TYPE' || suggestion.type === 'POPULAR_PRODUCT_TYPE'
      ? `product link:${suggestion.code.toLowerCase()}:${replaceSpecialChars(suggestion.description)}`
      : 'predictive dropdown';

  window.dataLayer.push({
    event: 'visitor interaction',
    interaction_name: 'perform search',
    search_type: searchType,
    search_href: `/${currentPagePath.splice(startPathWith, currentPagePath.length).join('/')}`,
  });
}

export function dlTrackOpenSearch() {
  window.dataLayer.push({
    event: 'visitor interaction',
    interaction_name: 'open search',
  });
}
