import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import ModalWrapper from 'app/commonComponents/modal/modalWrapper';
import { dlTrackSiteSwitch } from 'app/analytics/siteSwitchAnalytics';
import { dlTrackFormFields, dlTrackFormSubmit } from 'app/analytics/formsAnalytics';

class SiteSelectorModal extends Component {
  static getInitialState(props) {
    return {
      currentCountrySelect: props.locale.country.toUpperCase(),
      currentLanguageSelect: null,
      currentLanguageLink: null,

      countryList: [],
      languageList: [],
      countryDefault: `${props.locale.country.toUpperCase()}`,
      languageDefault: `${props.locale.language.toLowerCase()}_${props.locale.country.toUpperCase()}`,
    };
  }

  constructor(props) {
    super(props);

    this.state = SiteSelectorModal.getInitialState({ ...this.props });

    this.handleCountrySelect = this.handleCountrySelect.bind(this);
    this.handleLanguageSelect = this.handleLanguageSelect.bind(this);
    this.handleCountryList = this.handleCountryList.bind(this);
    this.handleLanguageList = this.handleLanguageList.bind(this);
    this.createButtonLink = this.createButtonLink.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    this.handleCountryList();
  }

  // eslint-disable-next-line class-methods-use-this
  onBlur(e) {
    dlTrackFormFields({
      formName: 'siteSelector',
      formField: e.target.name,
    });
  }

  handleCountryList() {
    const { availableStores } = this.props;
    this.setState(
      {
        countryList: Object.values(availableStores.stores).map(sites => (
          <option value={sites.uid} key={sites.uid}>
            {sites.name}
          </option>
        )),
      },
      () => this.handleLanguageList()
    );
  }

  handleCountrySelect(e) {
    if (e) {
      this.setState(
        {
          currentCountrySelect: e.target.value,
        },
        () => this.handleLanguageList(e)
      );
    } else {
      this.handleLanguageList();
    }
  }

  handleLanguageList(e) {
    const { availableStores } = this.props;
    const { currentCountrySelect } = this.state;
    const store = availableStores.stores.filter(({ uid }) => currentCountrySelect === uid)[0];
    const defaultLanguage = store.defaultLanguage.isocode;
    if (store.languages) {
      const languageList = Object.values(store.languages).map(language => {
        if (language.active) {
          return (
            <option value={language.isocode} key={language.isocode}>
              {language.name}
            </option>
          );
        }
        return null;
      });

      this.setState(
        {
          languageList,
        },
        () => {
          if (e) {
            this.setState(
              {
                languageDefault: defaultLanguage,
                currentLanguageSelect: defaultLanguage,
              },
              () => this.createButtonLink()
            );
          }
        }
      );
    }
  }

  handleLanguageSelect(e) {
    this.setState(
      {
        currentLanguageSelect: e.target.value,
      },
      () => this.createButtonLink()
    );
  }

  createButtonLink() {
    const { currentLanguageSelect } = this.state;
    const currentLanguageLink =
      currentLanguageSelect !== null ? currentLanguageSelect.replace(/^(\w+)(_)(\w+)$/, '/$3/$1/').toLowerCase() : null;
    this.setState({
      currentLanguageLink,
    });
  }

  handleClick(e) {
    e.preventDefault();
    const { currentLanguageLink, currentCountrySelect, currentLanguageSelect } = this.state;
    const { locale, modalToggle } = this.props;
    dlTrackFormSubmit({
      errorMsgs: {},
      formName: 'siteSelector',
    });
    if (currentLanguageLink !== `/${locale.country}/${locale.language}/` && currentLanguageLink !== null) {
      dlTrackSiteSwitch({
        country: currentCountrySelect,
        language: currentLanguageSelect,
      });
      window.location.href = currentLanguageLink;
    } else {
      modalToggle();
    }
  }

  render() {
    const { isModalOpen } = this.props;
    const { languageList, countryList, countryDefault, languageDefault, currentCountrySelect, currentLanguageSelect } =
      this.state;
    if (isModalOpen === false) {
      return null;
    }

    return (
      <ModalWrapper {...this.props} maxWidth={500}>
        <h2 className="site-selector__title">
          <FormattedMessage id="modal.siteSelector.modalTitle" description="Modal title" />
        </h2>
        <p className="site-selector__body">
          <FormattedMessage id="modal.siteSelector.modalUserLocation" description="Modal user location" />
        </p>
        <p className="site-selector__body">
          <FormattedMessage id="modal.siteSelector.modalUserNotice" description="Modal user notice" />
        </p>
        <form name="siteSelectorForm">
          <select
            name="country"
            className="site-selector__dropbox"
            defaultValue={countryDefault}
            value={currentCountrySelect}
            onChange={this.handleCountrySelect}
            onBlur={this.onBlur}
          >
            {countryList}
          </select>
          {languageList.length > 1 && (
            <select
              name="language"
              className="site-selector__dropbox"
              defaultValue={languageDefault}
              value={currentLanguageSelect}
              onChange={this.handleLanguageSelect}
              onBlur={this.onBlur}
            >
              {languageList}
            </select>
          )}
          <button className="site-selector__button" type="submit" onClick={this.handleClick}>
            <FormattedMessage id="modal.siteSelector.modalConfirmationButton" description="Modal confirmation button" />
          </button>
        </form>
      </ModalWrapper>
    );
  }
}

SiteSelectorModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  locale: PropTypes.objectOf(PropTypes.any).isRequired,
  availableStores: PropTypes.objectOf(PropTypes.any).isRequired,
  modalToggle: PropTypes.func.isRequired,
};

export default SiteSelectorModal;
