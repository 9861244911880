import React, { useRef, useLayoutEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

function ReadMore({ children }) {
  const containerRef = useRef();
  const textRef = useRef();
  const [isOverflowed, setIsOverflowed] = useState(false);

  const handleReadMoreClick = () => {
    containerRef.current.classList.toggle('expanded');
  };

  useLayoutEffect(() => {
    setIsOverflowed(textRef.current.offsetHeight > 105); // heigt of 4 lines
  }, []);

  return (
    <div
      ref={containerRef}
      className={`trustpilot-reviews__review-description-container ${isOverflowed ? '' : 'expanded'}`}
    >
      <p ref={textRef} className="trustpilot-reviews__review-description">
        {children}
      </p>
      {isOverflowed && (
        <button type="button" onClick={handleReadMoreClick} className="button-reset trustpilot-reviews__toggle">
          <strong className="trustpilot-reviews__read-less">
            <FormattedMessage id="trustpilot.reviews.read.less" />
          </strong>
          <strong className="trustpilot-reviews__read-more">
            <FormattedMessage id="trustpilot.reviews.read.more" />
          </strong>
        </button>
      )}
    </div>
  );
}

export default ReadMore;
