import { accordionTransitionEffect } from 'app/utils/deprecated_accordionTransition';
import mediaQueries from 'app/utils/mediaQueries';
import createAccordion from './accordion';

let isLargeUp = mediaQueries.is_large_up();
const accordions = [];

[...document.querySelectorAll('.accordion-container')].forEach((accordionContainer, index) => {
  accordions[index] = createAccordion(accordionContainer);
  accordions[index].init();
});

window.addEventListener('resize', () => {
  const currentIsLargeUp = mediaQueries.is_large_up();
  if (isLargeUp !== currentIsLargeUp) {
    isLargeUp = currentIsLargeUp;
    accordions.forEach(accordion => {
      accordion.destroy();
      accordion.init();
    });
  }
});

const accordion = {
  bindEvents() {
    const $accordionTitles = document.querySelectorAll('.accordion-item > a');
    accordionTransitionEffect($accordionTitles).add();
  },
};

accordion.bindEvents();
