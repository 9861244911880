export const UPDATE_SEARCH_QUERY = 'UPDATE_SEARCH_QUERY';
export const RECEIVE_AUTOCOMPLETE_SUGGESTIONS = 'RECEIVE_AUTOCOMPLETE_SUGGESTIONS';

export const TERM_TYPE = 'TERM_TYPE';
export const PRODUCT_TYPE = 'PRODUCT_TYPE';
export const TRENDING_TYPE = 'TRENDING_TYPE';
export const POPULAR_PRODUCT_TYPE = 'POPULAR_PRODUCT_TYPE';

export const TERM_SECTION = 'TERM';
export const PRODUCT_SECTION = 'PRODUCT';
export const CATEGORY_SECTION = 'CATEGORY';
