import { getLoggedUserInfo, getGlobalCartInfo, waitPageLoadAnalytics } from 'app/utils/globalDataHelpers';
import { getStorageData, saveStorageData } from 'app/utils/localStorage/localStorage';
import { SESSION_STORAGE } from 'app/utils/localStorage/localStorageConstants';
import { DL_USER_LOGGED_STATUS, DL_USER_BAG_ID } from './gtmAnalyticsConstants';

export function dlTrackCustomerData() {
  Promise.all([getLoggedUserInfo(), getGlobalCartInfo(), waitPageLoadAnalytics()]).then(
    async ([userInfo, cartData]) => {
      const customerInfo = userInfo['account/accountInfo'];
      const isLoggedInUser = !!userInfo['userInfo/index']?.username;
      const { encodedId } = userInfo['userInfo/index'] ?? '';
      const dataLayerInfo = {
        event: 'get data',
        user_status: isLoggedInUser ? 'logged in' : 'not logged in',
      };

      if (getStorageData(DL_USER_BAG_ID, SESSION_STORAGE) !== cartData.id && cartData.id && cartData.entries.length) {
        saveStorageData(DL_USER_BAG_ID, cartData.id, SESSION_STORAGE);
        dataLayerInfo.bag_id = cartData.id;
      }

      if (isPostLoginPage(isLoggedInUser)) {
        dataLayerInfo.customer_id = encodedId || '';
        dataLayerInfo.customer_lifetime_days = customerInfo.lifetimeDays || 'not available';
        dataLayerInfo.customer_birthday_days = customerInfo.birthdayDays || 'not available';
        dataLayerInfo.customer_first_purchase_days = customerInfo.firstOrderDays || (customerInfo.allOrders ? 0 : '');
        dataLayerInfo.customer_latest_purchase_days = customerInfo.lastOrderDays || (customerInfo.allOrders ? 0 : '');
        dataLayerInfo.customer_latest_purchase_revenue =
          customerInfo.lastOrderRevenue || (customerInfo.allOrders ? 0 : '');
        dataLayerInfo.customer_latest_purchase_delivery =
          customerInfo.lastOrderDelivery || (customerInfo.allOrders ? 0 : '');
        dataLayerInfo.customer_latest_purchase_discount =
          customerInfo.lastOrderDiscount || (customerInfo.allOrders ? 0 : '');
        dataLayerInfo.customer_lifetime_purchases = customerInfo.allOrders || 0;
        dataLayerInfo.customer_lifetime_revenue = customerInfo.allOrdersRevenue || 0;
        dataLayerInfo.customer_lifetime_delivery = customerInfo.allOrdersDelivery || 0;
        dataLayerInfo.customer_lifetime_discount = customerInfo.allOrdersDiscount || 0;
        dataLayerInfo.sign_in_type = 'email';
        dataLayerInfo.customer_gender = customerInfo.gender || '';
        dataLayerInfo.customer_newsletter_subscription = computeNewsletterSubscriptionValue(
          userInfo['account/accountInfo'].newsletters
        );
      }

      window.dataLayer.push(dataLayerInfo);
      syncUserLoggedInStatus(isLoggedInUser);
    }
  );
}

function computeNewsletterSubscriptionValue(subscriptions) {
  const value = (subscriptions.length === 2 && 'both') || subscriptions[0];
  return value ? `subscribed - ${value}` : 'not subscribed';
}

function syncUserLoggedInStatus(apiLoggedInStatus) {
  saveStorageData(DL_USER_LOGGED_STATUS, apiLoggedInStatus, SESSION_STORAGE);
}

function isPostLoginPage(apiSaysUserLoggedIn) {
  const sessionStorageSaysUserIsGuest = !getStorageData(DL_USER_LOGGED_STATUS, SESSION_STORAGE);

  return apiSaysUserLoggedIn && sessionStorageSaysUserIsGuest;
}
