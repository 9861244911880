import mediaQueries from 'app/utils/mediaQueries';
import { MINI_CART_ROOT_ELEMENT } from 'app/header/miniCart/miniCartConstants';
import { hoverIntent } from './hoverIntent';

const header = document.querySelector('.header');
const promoWrapper = document.querySelector('.header__promo-wrapper');
const searchPanel = document.querySelector('.global-header__search-container');

const toggleOpaqueClass = toggle => {
  const classNameMap = {
    add() {
      header.classList.add('opaque');
      promoWrapper.classList.add('opaque');
    },
    remove() {
      header.classList.remove('opaque');
      promoWrapper.classList.remove('opaque');
    },
  };

  if (window.pageYOffset > 0) {
    classNameMap.add();
  } else {
    classNameMap[toggle]();
  }
};

const hoverEventHandler = toggle => {
  const isMediumUp = mediaQueries.is_medium_up();
  if (
    !isMediumUp ||
    searchPanel.classList.contains('is-active') ||
    MINI_CART_ROOT_ELEMENT.classList.contains('is-active')
  ) {
    return;
  }

  toggleOpaqueClass(toggle);
};

const scrollEventHandler = () => {
  const isLargeUp = mediaQueries.is_large_up();
  const submenuList = document.querySelectorAll('.menu-level-one .menu-level-one__item .content');
  const isSubmenuClosed = [...submenuList].every(el =>
    isLargeUp ? window.getComputedStyle(el).display === 'none' : window.getComputedStyle(el).display === 'block'
  );

  if (
    window.pageYOffset === 0 &&
    isSubmenuClosed &&
    !searchPanel.classList.contains('is-active') &&
    !MINI_CART_ROOT_ELEMENT.classList.contains('is-active')
  ) {
    toggleOpaqueClass('remove');
    return;
  }

  toggleOpaqueClass('add');
};

export default function handleTransparentHeader() {
  if (!document.documentElement.classList.contains('is-header-transparent')) {
    return;
  }
  hoverIntent(
    [header, promoWrapper],
    () => {
      hoverEventHandler('add');
    },
    () => {
      hoverEventHandler('remove');
    }
  ).add();
  header.addEventListener('click', () => {
    toggleOpaqueClass('add');
  });
  window.addEventListener('scroll', scrollEventHandler);
  scrollEventHandler();
}
