import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const MiniIcon = ({ totalItems, isOpened, openOrRedirect, keyboardToggle, miniType, baseUrl }) => {
  const newIconMapping = miniType === 'bag' ? 'fa-light fa-bag-shopping' : 'fa-light fa-heart';

  return (
    <a
      href={baseUrl}
      onClick={openOrRedirect}
      onKeyDown={keyboardToggle}
      className={`nav__btn nav__btn--${miniType} ${isOpened ? `nav__btn--active` : ''}`}
      aria-label={`Toggle ${miniType}`}
    >
      {totalItems === 0 ? (
        <i className={`nav__btn-icon nav__btn-icon--${miniType} ${newIconMapping}`} />
      ) : (
        <Fragment>
          <span className="show-for-sr">{miniType}</span>
          <i className={`nav__btn-icon nav__btn-icon--${miniType} ${newIconMapping}`} />
          <span className="nav__btn-icon counter">{totalItems}</span>
        </Fragment>
      )}
    </a>
  );
};

MiniIcon.propTypes = {
  isOpened: PropTypes.bool.isRequired,
  totalItems: PropTypes.number.isRequired,
  openOrRedirect: PropTypes.func.isRequired,
  keyboardToggle: PropTypes.func.isRequired,
  miniType: PropTypes.string.isRequired,
  baseUrl: PropTypes.string.isRequired,
};

export default MiniIcon;
