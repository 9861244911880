import DOMPurify from 'dompurify';
import { ajaxRequest } from 'app/utils/helpers';

const renderTemplate = (template, response) => {
  const $result = document.createElement('div');
  const wrapper = document.createElement('div');
  $result.classList.add('row');
  wrapper.innerHTML = template;

  [].forEach.call(wrapper.querySelectorAll('[data-each]'), element => {
    const dataContext = response[element.dataset.each] || response;
    [].forEach.call(dataContext, (data, dataIndex) => {
      const limit = parseInt(element.dataset.limit, 10);
      if (!Number.isNaN(limit) && dataIndex >= limit) {
        return false;
      }
      const $item = element.cloneNode(true);
      const elementsWithDataStringAttr = $item.querySelectorAll('[data-string]');
      const elementsWithDataEmailAttr = $item.querySelectorAll('[data-email]');
      const elementsWithDataStringAttrLength = elementsWithDataStringAttr.length;
      const elementsWithDataEmailAttrLength = elementsWithDataEmailAttr.length;

      for (let i = 0; i < elementsWithDataStringAttrLength; i++) {
        elementsWithDataStringAttr[i].textContent =
          dataContext[dataIndex][elementsWithDataStringAttr[i].dataset.string];
      }
      for (let i = 0; i < elementsWithDataEmailAttrLength; i++) {
        const email = dataContext[dataIndex][elementsWithDataEmailAttr[i].dataset.email];
        elementsWithDataEmailAttr[i].textContent = email;
        elementsWithDataEmailAttr[i].setAttribute('href', `mailto:${email}`);
      }
      $result.appendChild($item);
      return true;
    });
  });
  return $result.outerHTML;
};

const elementsWithDataJsonUrlAttr = document.querySelectorAll('[data-json-url]');
const elementsWithDataJsonUrlAttrLength = elementsWithDataJsonUrlAttr.length;

for (let i = 0; i < elementsWithDataJsonUrlAttrLength; i++) {
  const template = elementsWithDataJsonUrlAttr[i].innerHTML;
  ajaxRequest('GET', elementsWithDataJsonUrlAttr[i].dataset.jsonUrl, {}, null, false, {}).then(data => {
    elementsWithDataJsonUrlAttr[i].innerHTML = DOMPurify.sanitize(renderTemplate(template, data));
  });
}
