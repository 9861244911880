import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Image from 'app/utils/image';
import { convertCurrencyToNumber } from 'app/utils/helpers';
import { extractProductClearanceStatus } from 'app/analytics/qubit/qubitDataUtils';

const MiniProducts = ({ entries, shoppingList }) => {
  return (
    <div className={`mini__products ${!entries.length ? 'mini__products--empty' : ''}`}>
      {entries.length
        ? entries.map(product => (
            <div
              key={product.id}
              data-sku={product.baseId}
              data-product-colour-size-sku={product.id}
              className="mini__product-wrapper"
              data-classification-status={extractProductClearanceStatus(product.dataLayerMap)}
            >
              <div className="mini__product">
                <div className="mini__product-image">
                  <a href={product.link}>
                    <Image alt={product.name} media={{ url: product.baseImage }} sizes={{ s: '100px' }} />
                  </a>
                </div>
                <div className="mini__product-data">
                  <p>
                    <span className="mini__product-name" dangerouslySetInnerHTML={{ __html: `${product.name} ` }} />
                    <span
                      className="mini__product-description"
                      dangerouslySetInnerHTML={{
                        __html: `${product.description}`,
                      }}
                    />
                  </p>
                  <p>
                    <span className="mini__product-color">
                      <FormattedMessage id="order.color" description="Colour" />:
                      {` ${product.color || product.features.footwear.color.featureValues[0].value}`}
                    </span>
                    <br />
                    <span className="mini__product-size">
                      <FormattedMessage id="order.size" description="Size" />:{' '}
                      <span
                        dangerouslySetInnerHTML={{
                          __html: product.variationValues !== null ? product.variationValues[0].name : '',
                        }}
                      />
                    </span>
                  </p>
                  <p className="mini__product-price">
                    {convertCurrencyToNumber(product.wasPrice) ? (
                      <Fragment>
                        <span className="mini__product-price--was-regular">{`${product.unitPrice} `}</span>
                        <span className="mini__product-price--was">{` ${product.wasPrice}`}</span>
                      </Fragment>
                    ) : (
                      <span className="mini__product-price--regular">{product.unitPrice}</span>
                    )}
                  </p>
                  {product.quantity > 1 && (
                    <p className="mini__product-quantity">
                      <FormattedMessage id="mini.cart.qty" description="Start shopping" /> {product.quantity}
                    </p>
                  )}
                </div>
              </div>
            </div>
          ))
        : shoppingList && (
            <div className="mini__cms-content">
              <p className="mini__cms-content-title">
                <FormattedMessage id="empty.mini.cart.start.shopping.label" description="Start shopping" />
              </p>
              <ul className="mini__cms-content-list" dangerouslySetInnerHTML={{ __html: shoppingList.innerHTML }} />
            </div>
          )}
    </div>
  );
};

MiniProducts.defaultProps = {
  shoppingList: undefined,
};

MiniProducts.propTypes = {
  entries: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.object])).isRequired,
  shoppingList: PropTypes.element,
};

export default MiniProducts;
