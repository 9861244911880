import { ajaxRequest } from '../../utils/helpers';
import { showError } from '../../globalMessages/globalMessagesClient';

const { exchangeUrl } = window.inlineGlobalConfig;

export const useExchangeDelete = () => {
  const exchangeDeleteRequest = () => {
    return ajaxRequest('DELETE', exchangeUrl, {}).then(res => {
      if (res.status === 'error') {
        showError({
          message: res.message,
          autoHide: true,
          displayTime: 10,
          sticky: true,
        });
        return res;
      }
        window.inlineExchange = {
          exchangeRequest: { data: null },
        };
        return res
    });
  };

  return { exchangeDeleteRequest };
};
