import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const AccountNameTitle = ({ accountName }) => (
  <Fragment>
    <span>{accountName}</span>
  </Fragment>
);

AccountNameTitle.propTypes = {
  accountName: PropTypes.string.isRequired,
};

export default AccountNameTitle;
