import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const MiniCartSummary = ({ totalItems, subtotal, closeOrRedirect, exchange }) => (
  <div className={`mini__summary ${!totalItems ? 'mini__summary--empty' : ''}`}>
    {totalItems ? (
      <div className="mini__summary-wrapper">
        <div className="mini__summary-info">
          <p>
            <strong>{totalItems}</strong>{' '}
            {totalItems === 1 ? (
              <FormattedMessage id="cart.summary.count.item" description="Item" />
            ) : (
              <FormattedMessage id="cart.summary.count.items" description="Items" />
            )}
          </p>
          {subtotal[1] !== '0' && (
            <p className="mini__summary-info-bold">
              <FormattedMessage id="cart.summary.subtotal" description="Subtotal" />
              &nbsp;
              <span className="price">{subtotal}</span>
            </p>
          )}
        </div>
        <div className="mini__summary-button">
          <button type="button" onClick={() => closeOrRedirect()}>
            <FormattedMessage id="checkout.checkout" description="Checkout" />
          </button>
        </div>
      </div>
    ) : (
      <>
        <p className="mini__summary-empty-bag">
          <FormattedMessage id="cart.emptyBag" description="Empty bag" />
        </p>
        {exchange && (
          <>
            <div className="mini__summary-empty-container">
              {exchange?.amount && (
                <div className="mini__summary-exchange">
                  <span className="mini__summary-exchange-label">
                    <FormattedMessage id="order.summary.order.exchange" description="Exchange Credit" />
                  </span>
                  <span className="mini__summary-exchange-value">-{exchange.amount}</span>
                </div>
              )}
              {exchange?.amountToRefund ? (
                <div className="mini__summary-exchange-refund">
                  <FormattedMessage id="order.summary.order.exchangeRefund" description="Refund due" />
                  <span className="mini__summary-exchange-refund-value">{exchange.amountToRefund}</span>
                </div>
              ) : (
                <div className="mini__summary-subtotal">
                  <FormattedMessage id="cart.summary.subtotal" description="Subtotal" />
                  <span className="mini__summary-subtotal-value">{subtotal}</span>
                </div>
              )}
            </div>
          </>
        )}
      </>
    )}
  </div>
);

MiniCartSummary.propTypes = {
  totalItems: PropTypes.number.isRequired,
  subtotal: PropTypes.string.isRequired,
  closeOrRedirect: PropTypes.func.isRequired,
};

export default MiniCartSummary;
