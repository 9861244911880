export function dlTrackOrderDetailsGlobalMessage(message) {
  window.dataLayer.push({
    user_status: 'logged in',
    order_detail_error: message.toLowerCase(),
  });
}

export function dlTrackEmptyBagGlobalMessage() {
  window.dataLayer.push({
    event: 'visitor interaction',
    interaction_name: 'bag error',
    bag_error_message: 'your shopping bag is empty',
  });
}
