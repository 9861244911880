import React, { useEffect, useState } from 'react';
import { OPEN_SEARCH, CLOSE_SEARCH } from 'app/cmscomponents/header/headerConstants';

const AutoSuggest = React.lazy(() =>
  import(
    /* webpackChunkName: "AutoSuggest" */
    './components/autoSuggest/autoSuggest'
  )
);

const SearchApp = ({ props }) => {
  const [hasSearchBeenOpened, setHasSearchBeenOpened] = useState(false);

  useEffect(() => {
    document.addEventListener(OPEN_SEARCH, () => {
      setHasSearchBeenOpened(true);
    });

    const searchPanel = document.querySelector('.global-header__search-container');
    searchPanel.addEventListener(CLOSE_SEARCH, () => {
      setHasSearchBeenOpened(false);
    });
  }, []);

  return (
    hasSearchBeenOpened && (
      <React.Suspense>
        <AutoSuggest {...props} hasSearchBeenOpened={hasSearchBeenOpened} />
      </React.Suspense>
    )
  );
};

export default SearchApp;
