import React from 'react';
import ReactDOM from 'react-dom';
import ModalWrapper from '../../../commonComponents/modal/modalWrapper';

export const CustomModalWrapper = ({ isModalOpen, children, ...props }) => {
  if (!isModalOpen) return null;

  const modalRoot = document.querySelector('.main-wrapper');
  return ReactDOM.createPortal(<ModalWrapper {...props}>{children}</ModalWrapper>, modalRoot);
};
