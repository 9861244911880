import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const MiniWishlistOptions = ({ accountIsLogged, entries, retrieveBag, closeOrRedirect, miniType }) => {
  if (entries.length) {
    return (
      <div className="mini__options">
        <div className={`mini__options-action mini__options-action--${miniType}`}>
          <button type="button" onClick={() => closeOrRedirect()}>
            <FormattedMessage id="wishlist.view.label" description="View wishlist" />
          </button>
        </div>
      </div>
    );
  }

  if (!accountIsLogged) {
    return <div className="mini__options" dangerouslySetInnerHTML={{ __html: retrieveBag.innerHTML }} />;
  }

  return null;
};

MiniWishlistOptions.defaultProps = {
  retrieveBag: undefined,
};

MiniWishlistOptions.propTypes = {
  accountIsLogged: PropTypes.bool.isRequired,
  entries: PropTypes.arrayOf(PropTypes.object).isRequired,
  closeOrRedirect: PropTypes.func.isRequired,
  miniType: PropTypes.string.isRequired,
  retrieveBag: PropTypes.instanceOf(Element),
};

export default MiniWishlistOptions;
