import { RECEIVE_MINI_WISHLIST, MINI_WISHLIST_IS_FETCHING } from './miniWishlistConstants';

export const miniWishlist = (
  state = {
    id: null,
    entries: [],
    totalItems: 0,
    isMiniWishlistFetching: false,
  },
  action
) => {
  switch (action.type) {
    case MINI_WISHLIST_IS_FETCHING:
      return {
        ...state,
        isMiniWishlistFetching: true,
      };
    case RECEIVE_MINI_WISHLIST:
      return {
        ...state,
        id: action.id,
        entries: action.entries,
        totalItems: action.totalItems,
        isMiniWishlistFetching: false,
      };
    default:
      return state;
  }
};
