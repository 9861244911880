import React from 'react';
import PropTypes from 'prop-types';

import { FormattedMessage } from 'react-intl';

const SiteSelectorButton = ({ modalToggle, locale, availableStores }) => {
  const handleButtonClick = () => {
    modalToggle();
  };

  const currentCountry = availableStores?.stores?.find(store => store.uid === locale.country.toUpperCase());

  return (
    <button className="site-selector__action-button" onClick={handleButtonClick} type="button">
      <FormattedMessage id="modal.siteSelector.actionButton" description="Action button for changing language" />:{' '}
      <span className="site-selector__action-button-country">
        {currentCountry?.name}
        {'  '}
        <i className="fa-regular fa-chevron-down" />
      </span>
      {/*  TODO refactor with an icon library or so */}
    </button>
  );
};

SiteSelectorButton.propTypes = {
  locale: PropTypes.objectOf(PropTypes.any).isRequired,
  modalToggle: PropTypes.func.isRequired,
};

export default SiteSelectorButton;
