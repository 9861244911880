import { findAncestorBySelector } from 'app/utils/helpers';
import { replaceSpecialChars, getElementIndex, pushAnalyticsOnTabClick } from './gtmAnalyticsUtils';

const menuEvents = () => {
  const menu = document.querySelector('.menu');
  if (!menu) {
    return;
  }

  menu.addEventListener('click', e => {
    try {
      if (e.target.nodeName.toLowerCase() === 'a') {
        const navBarData = {
          event: 'visitor interaction',
          interaction_name: 'nav bar click',
        };

        const levelTwoRow = findAncestorBySelector(e.target, '.menu-level-two__row');
        const levelTwoItem = findAncestorBySelector(e.target, '.menu-level-two__item');
        let subsectionText;
        if (levelTwoRow) {
          const columns = findAncestorBySelector(e.target, '.columns');
          subsectionText = columns.querySelector('.menu__cat-title').dataset.titleen;
          Object.assign(navBarData, {
            nav_click_subsection_text: replaceSpecialChars(subsectionText || ''),
            nav_click_subsection_number: getElementIndex(columns) + 1,
          });
        } else if (levelTwoItem) {
          subsectionText = levelTwoItem.querySelector('.menu__cat-title').dataset.titleen;
          Object.assign(navBarData, {
            nav_click_subsection_text: replaceSpecialChars(subsectionText || ''),
            nav_click_subsection_number: getElementIndex(levelTwoItem) + 1,
          });
        }

        const section = findAncestorBySelector(e.target, '.menu-level-one__item');
        const sectionText = section.children[0].dataset.titleen;
        const sectionIndex = getElementIndex(section);

        const linkText = e.target.dataset.titleen;
        const linkIndex = levelTwoRow || levelTwoItem ? getElementIndex(e.target.parentNode) + 2 : 1;

        window.dataLayer.push(
          Object.assign(navBarData, {
            nav_click_section_text: replaceSpecialChars(sectionText || ''),
            nav_click_section_number: sectionIndex,
            nav_click_link_text: replaceSpecialChars(linkText || ''),
            nav_click_link_number: linkIndex,
          })
        );
      }
    } catch (error) {
      console.error(error);
    }
  });
};

const promoBarEvents = () => {
  const promoBar = document.querySelector('.header__promo');

  if (!promoBar) {
    return;
  }

  promoBar.addEventListener('click', e => {
    if (e.target.nodeName.toLowerCase() === 'a') {
      e.preventDefault();
      try {
        const currentPagePath = window.location.pathname.split('/');
        window.dataLayer.push({
          event: 'visitor interaction',
          interaction_name: 'promo bar click',
          promo_bar_link_text: replaceSpecialChars(e.target.text),
          promo_bar_link_number: getElementIndex(e.target) + 1,
          promo_bar_link_href: `/${currentPagePath.splice(3, currentPagePath.length).join('/')}`,
          eventCallback: () => {
            window.location = e.target.getAttribute('href');
          },
        });
      } catch (err) {
        console.error(err);
      }
    }
  });
};

const socialLinksEvents = () => {
  const socialLinks = document.querySelector('.footer__social-links');

  if (!socialLinks) {
    return;
  }

  socialLinks.addEventListener('click', e => {
    if (e.target.nodeName.toLowerCase() === 'a') {
      window.dataLayer.push({
        event: 'visitor interaction',
        interaction_name: 'social follow click',
        social_follow_network: e.target.className.split('-')[1].replace(/[0-9]/g, ''),
      });
    }
  });
};

const clickToCallEvents = () => {
  const clickToCalls = document.querySelectorAll('.click-to-call');

  if (!clickToCalls.length) {
    return;
  }

  [].forEach.call(clickToCalls, element => {
    element.addEventListener('click', e => {
      try {
        window.dataLayer.push({
          event: 'visitor interaction',
          interaction_name: 'click to call',
          call_number: e.target.innerText.replace(/\D+/g, ''),
        });
      } catch (err) {
        console.error(err);
      }
    });
  });
};

const clickToEmailEvents = () => {
  const clickToEmails = document.querySelectorAll('.click-to-email');

  if (!clickToEmails.length) {
    return;
  }

  [].forEach.call(clickToEmails, element => {
    element.addEventListener('click', () => {
      try {
        window.dataLayer.push({
          event: 'visitor interaction',
          interaction_name: 'click to email',
        });
      } catch (err) {
        console.error(err);
      }
    });
  });
};

const accordionEvents = () => {
  const accordions = document.querySelectorAll('.accordion-item__heading');

  if (!accordions.length) {
    return;
  }

  [...accordions].forEach(element => {
    element.addEventListener('click', pushAnalyticsOnTabClick);
  });
};

const tileEvents = () => {
  const tiles = document.querySelectorAll('.section-option');

  if (!tiles.length) {
    return;
  }

  [].forEach.call(tiles, element => {
    element.addEventListener('click', () => {
      const heading = element.querySelector('h3');
      const name = replaceSpecialChars(heading ? heading.innerText : '');

      try {
        if (name) {
          window.dataLayer.push({
            event: 'visitor interaction',
            interaction_name: 'tab click',
            tab_name: name.length - name.lastIndexOf('tm') === 2 ? name.slice(0, -2) : name,
          });
        }
      } catch (err) {
        console.error(err);
      }
    });
  });
};

const storeLocatorEvents = () => {
  const STORE_LOCATOR_SEARCH_TIMEOUT = 1500;

  const storeLocator = document.querySelector('.store-locator__button');

  if (!storeLocator) {
    return;
  }

  try {
    storeLocator.addEventListener('click', () => {
      setTimeout(() => {
        const fullPostCode = document.querySelector('.store-locator__input').value;
        const addresses = [].slice.call(document.querySelector('.store-locator__list').children[0].children);
        const nearestDistance = addresses.slice(0, 3).map(address => {
          const distance = address.querySelector('.loc-dist');
          return distance.innerText.match(/[+-]?([0-9]*[.])?[0-9]+/g, '')[0];
        });

        window.dataLayer.push({
          event: 'visitor interaction',
          interaction_name: 'store locator search',
          postcode_area_district: replaceSpecialChars(fullPostCode || ''),
          distance_one: Number(parseFloat(nearestDistance[0]).toFixed(2)) || '',
          distance_two: Number(parseFloat(nearestDistance[1]).toFixed(2)) || '',
          distance_three: Number(parseFloat(nearestDistance[2]).toFixed(2)) || '',
        });
      }, STORE_LOCATOR_SEARCH_TIMEOUT);
    });
  } catch (err) {
    console.error(err);
  }
};

export default function dlTrackFooterEvents() {
  socialLinksEvents();
  promoBarEvents();
  menuEvents();
  clickToCallEvents();
  clickToEmailEvents();
  accordionEvents();
  tileEvents();
  storeLocatorEvents();
}
