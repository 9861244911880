import { isElementVisible, fadeIn, fadeOut, topWrapperOffset, usernamePadding } from 'app/utils/helpers';
import { isIOS } from 'app/utils/browserHelpers';
import { accordionTransitionEffect } from 'app/utils/deprecated_accordionTransition';
import mediaQueries from 'app/utils/mediaQueries';
import { dlTrackOpenSearch } from 'app/analytics/searchAnalytics';
import { isSpacePressed, isEscPressed } from 'app/utils/accessibility/accessibilityUtils';
import { HEADER_MINI_CLOSE_ALL } from 'app/header/headerConstants';
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';
import handleTransparentHeader from './transparentHeader';
import { OPEN_SEARCH, CLOSE_SEARCH, CLOSE_NAVIGATION } from './headerConstants';
import { hoverIntent } from './hoverIntent';
import './header.scss';
import '../../../../scss/cms.scss';
import '../../../../scss/common.scss';
import '../../../../scss/unsorted.scss';

let isLargeUp = mediaQueries.is_large_up();
const fadeSpeed = 200;

const menuActiveClass = 'is-active';
const navigationMenu = document.querySelector('.menu-level-one');
const navigationMenuTitles = document.querySelectorAll('.menu-level-one > li > a');
const navigationAccordion = document.querySelectorAll('.menu-level-one__item--has-children');
const navigationAccordionTitles = document.querySelectorAll('.menu-level-one__item--has-children > a');

const navigationContent = document.querySelectorAll('.menu-level-one__item--has-children .content');
const menuButton = document.querySelector('.js-menu');
const menuOverlay = document.querySelector('.header__nav .menu__overlay');
const hamburgerIcon = document.querySelector('.menu-btn__hamburger');
const crossIcon = document.querySelector('.menu-btn__cross');
const hamburgerLabel = document.querySelector('.menu-btn__hamburger-label');
const headerInput = document.querySelector('.nav__search-input');
const headerLogoLink = document.querySelector('.header__logo-link');

let topOffset = window.pageYOffset;
let searchButton = null;

if (mediaQueries.is_medium_up()) {
  [searchButton] = document.querySelectorAll('.nav__btn--search');
} else {
  [, searchButton] = document.querySelectorAll('.nav__btn--search');
}
const searchOverlay = document.querySelector('.search__overlay');

const searchPanel = document.querySelector('.global-header__search-container');

let accordionHandler;
accordionHandler = accordionTransitionEffect(navigationAccordionTitles);

let previouslySelectedTapMenuItem = null;

const toggleMenuIcons = () => {
  const isHamburgerHidden = menuButton.classList.contains(menuActiveClass);
  hamburgerIcon.style.display = isHamburgerHidden ? 'none' : 'inherit';
  crossIcon.style.display = isHamburgerHidden ? 'block' : 'none';
  hamburgerLabel.style.display = isHamburgerHidden ? 'none' : 'block';
};

const onDoubleTapMenu = e => {
  const element = e.currentTarget;
  if (!parseInt(element.dataset.clicks, 10) && !isElementVisible(element.nextElementSibling)) {
    e.preventDefault();
    element.dataset.clicks = 1;
    if (previouslySelectedTapMenuItem) {
      previouslySelectedTapMenuItem.dataset.clicks = 0;
      previouslySelectedTapMenuItem.nextElementSibling.style.display = 'none';
      element.nextElementSibling.style.display = 'block';
    }
    previouslySelectedTapMenuItem = e.currentTarget;
  }
};

const doubleTapMenu = (elements, toggle) => {
  if (toggle === 'on') {
    [].forEach.call(elements, element => element.addEventListener('click', onDoubleTapMenu));
  } else if (toggle === 'off') {
    [].forEach.call(elements, element => element.removeEventListener('click', onDoubleTapMenu));
  }
};

let currentlySelected = null;
let previouslySelected = null;
function mouseEnterMenuItem() {
  currentlySelected = this;
  if (
    currentlySelected !== null &&
    currentlySelected.parentNode.classList.contains('menu-level-one__item--has-children')
  ) {
    if (
      previouslySelected !== null &&
      previouslySelected.parentNode.classList.contains('menu-level-one__item--has-children')
    ) {
      previouslySelected.nextElementSibling.style.display = 'none';
      currentlySelected.nextElementSibling.style.display = 'block';
      menuOverlay.style.display = 'block';
    } else {
      currentlySelected.setAttribute('aria-expanded', 'true');
      currentlySelected.nextElementSibling.setAttribute('aria-hidden', 'false');
      fadeIn(currentlySelected.nextElementSibling, fadeSpeed);
      fadeIn(menuOverlay, fadeSpeed);
    }
  } else {
    if (previouslySelected) {
      fadeOut(previouslySelected.nextElementSibling, fadeSpeed);
      fadeOut(menuOverlay, fadeSpeed);
      previouslySelected.setAttribute('aria-expanded', 'false');
      previouslySelected.nextElementSibling.setAttribute('aria-hidden', 'true');
    }
    currentlySelected = null;
    previouslySelected = null;
  }
}

function mouseLeaveMenuItem() {
  if (
    currentlySelected !== null &&
    currentlySelected.parentNode.classList.contains('menu-level-one__item--has-children')
  ) {
    previouslySelected = currentlySelected;
    currentlySelected = this;
  }
}

const hoverIntentHandler = hoverIntent(navigationMenuTitles, mouseEnterMenuItem, mouseLeaveMenuItem);
const closeMenuOnKeydown = () => {
  fadeOut(currentlySelected.nextElementSibling, fadeSpeed);
  fadeOut(menuOverlay, fadeSpeed);
  currentlySelected.setAttribute('aria-expanded', 'false');
  currentlySelected.nextElementSibling.setAttribute('aria-hidden', 'true');
  currentlySelected = null;
  previouslySelected = null;
};

[].forEach.call(navigationMenuTitles, element => {
  element.addEventListener('keydown', function menuItemToggle(e) {
    if (isSpacePressed(e)) {
      e.preventDefault();
      document.dispatchEvent(new Event(HEADER_MINI_CLOSE_ALL));
      if (currentlySelected === this) {
        closeMenuOnKeydown();
      } else {
        if (currentlySelected !== null && currentlySelected.nextElementSibling) {
          fadeOut(currentlySelected.nextElementSibling, fadeSpeed);
          currentlySelected.setAttribute('aria-expanded', 'false');
          currentlySelected.nextElementSibling.setAttribute('aria-hidden', 'true');
        }
        mouseEnterMenuItem.call(this);
      }
    }
  });
});

document.addEventListener('keydown', e => {
  if (isEscPressed(e) && currentlySelected !== null && currentlySelected.nextElementSibling) {
    closeMenuOnKeydown();
  }
});

document.addEventListener(
  'focus',
  e => {
    if (currentlySelected !== null && currentlySelected.nextElementSibling) {
      const elInsideFocusable = currentlySelected.contains(e.target);
      const elInsideFocusableSibling = currentlySelected.nextElementSibling.contains(e.target);

      if (!elInsideFocusableSibling && !elInsideFocusable) {
        closeMenuOnKeydown();
      }
    }
  },
  true
);

document.addEventListener(CLOSE_NAVIGATION, () => {
  if (currentlySelected !== null && currentlySelected.nextElementSibling) {
    closeMenuOnKeydown();
  }
});

export const closeSearch = (event = {}) => {
  const current = event.currentTarget;
  document.querySelector('html').classList.remove('is-search-open');
  if (!mediaQueries.is_large_up()) {
    topWrapperOffset('close', topOffset, 'nav__no-scroll');
  }

  headerInput.value = '';
  searchButton.blur();
  searchButton.classList.remove(menuActiveClass);
  searchButton.setAttribute('aria-pressed', 'false');
  searchPanel.classList.remove(menuActiveClass);

  if (mediaQueries.is_large_up()) {
    hoverIntentHandler.add();
    if (current && current.classList.contains('search-close')) {
      searchButton.focus();
    }
  }

  searchPanel.dispatchEvent(
    new CustomEvent('searchSectionClosed', {
      detail: event,
    })
  );

  clearAllBodyScrollLocks();

  fadeOut(searchOverlay, fadeSpeed);
  doubleTapMenu(navigationAccordionTitles, 'on');
};

const onKeydownSearch = evt => {
  if (isEscPressed(evt)) {
    closeSearch();
    document.removeEventListener('keydown', onKeydownSearch);
    headerInput.blur();
  }
};

export const closeMenu = () => {
  menuButton.classList.remove(menuActiveClass);
  toggleMenuIcons();
  fadeOut(menuOverlay, fadeSpeed);
  document.querySelector('html').classList.remove('nav__no-scroll');
  document.querySelector('#wrapper').style.removeProperty('margin-top');
  window.scrollTo(0, topOffset);
};

headerLogoLink.addEventListener('click', event => {
  if (
    (mediaQueries.is_small_only() || mediaQueries.is_medium_only()) &&
    menuButton.classList.contains(menuActiveClass)
  ) {
    event.preventDefault();
    closeMenu();
  }
});

menuButton.addEventListener('click', event => {
  const element = event.currentTarget;
  if (element.classList.contains(menuActiveClass)) {
    closeMenu();
  } else {
    element.classList.add(menuActiveClass);
    toggleMenuIcons();
    fadeIn(menuOverlay, fadeSpeed);
    topOffset = window.pageYOffset;
    document.querySelector('html').classList.add('nav__no-scroll');
    document.querySelector('#wrapper').style.marginTop = `-${topOffset}px`;
  }
});

menuOverlay.addEventListener('click', closeMenu);

const handleClickOutsideSearch = event => {
  const isClickInsideSearchOverlay = searchOverlay.contains(event.target);
  const isClickInsideSearchButton = searchButton.contains(event.target);
  const isClickInsideSearchInput = headerInput && headerInput.contains(event.target);
  const isClickInsideAutoSuggestInput = document.querySelector('.react-autosuggest__input')?.contains(event.target);
  const isClickInsideSearchIcon = event.target.classList.contains('search-icon');

  if (
    !isClickInsideSearchOverlay &&
    !isClickInsideSearchButton &&
    !isClickInsideSearchInput &&
    !isClickInsideAutoSuggestInput &&
    !isClickInsideSearchIcon
  ) {
    closeSearch();
    document.removeEventListener('click', handleClickOutsideSearch);
  }
};

const openSearch = () => {
  topOffset = window.pageYOffset;
  if (isIOS() || !topOffset) {
    window.scrollTo(0, 0);
  }

  if (!isIOS()) {
    disableBodyScroll(document.querySelector('.react-autosuggest__form'));
  }

  searchButton.classList.add(menuActiveClass);
  if (!mediaQueries.is_large_up()) {
    ['nav__no-scroll', 'is-search-open'].map(klass => document.querySelector('html').classList.add(klass));
    document.querySelector('#wrapper').style.marginTop = `-${topOffset}px`;
  }
  searchPanel.classList.add(menuActiveClass);
  fadeIn(searchOverlay, fadeSpeed);
  searchButton.setAttribute('aria-pressed', 'true');
  if (mediaQueries.is_large_up()) {
    hoverIntentHandler.remove();
    doubleTapMenu(navigationAccordionTitles, 'off');
  }
  document.addEventListener('keydown', onKeydownSearch);
  document.addEventListener('click', handleClickOutsideSearch);

  const event = new CustomEvent(OPEN_SEARCH);
  document.dispatchEvent(event);
  document.dispatchEvent(new Event(HEADER_MINI_CLOSE_ALL));
  dlTrackOpenSearch();
};

searchButton.addEventListener('click', () => {
  closeMenu();
  if (!searchPanel.classList.contains(menuActiveClass)) {
    openSearch();
  }
});

searchButton.addEventListener('keydown', e => {
  if (isSpacePressed(e) && !isLargeUp) {
    document.querySelector('.react-autosuggest__input').focus();
  }
});

searchOverlay.addEventListener('click', () => {
  closeSearch();
});

searchPanel.addEventListener(CLOSE_SEARCH, closeSearch);

// Anchor replacement
const hrefDirect = [];
const hrefTarget = [];
[].forEach.call(navigationMenuTitles, element => {
  if (element.dataset.url) {
    hrefDirect.push(element.dataset.url);
    hrefTarget.push(element.getAttribute('href'));
  }
});

const replaceAnchorWith = (elements, ref) => {
  let menuItemsCount = 0;
  [].forEach.call(elements, element => {
    if (element.dataset.url && element.parentNode.classList.contains('menu-level-one__item--has-children')) {
      if (ref === 'data') {
        element.setAttribute('data-url', hrefDirect[menuItemsCount]);
        element.setAttribute('href', hrefTarget[menuItemsCount]);
      } else if (ref === 'url') {
        element.setAttribute('data-url', hrefTarget[menuItemsCount]);
        element.setAttribute('href', hrefDirect[menuItemsCount]);
      }
      menuItemsCount += 1;
    }
  });
};

const mouseLeaveOverlay = () => {
  previouslySelectedTapMenuItem = null;
  [].forEach.call(navigationAccordionTitles, element => element.setAttribute('data-clicks', 0));
  [].forEach.call(navigationContent, element => (isElementVisible(element) ? fadeOut(element, fadeSpeed) : false));
  fadeOut(menuOverlay, fadeSpeed);
  currentlySelected = null;
  previouslySelected = null;
};

function accordionSwitch() {
  if (mediaQueries.is_large_up()) {
    // Reset mobile menu behaviour on resize
    menuButton.classList.remove(menuActiveClass);
    [].forEach.call(navigationAccordion, element => element.classList.remove('active'));
    [].forEach.call(navigationContent, element => {
      element.style.removeProperty('opacity');
      element.style.removeProperty('display');
      element.setAttribute('aria-hidden', true);
    });
    menuOverlay.style.display = 'none';
    if (searchPanel.classList.contains('is-active')) {
      topWrapperOffset('close', topOffset, 'nav__no-scroll');
    }

    // Equal height navigation background
    // TODO inspect with different configuration of menu, to adjust elementHeight
    // const navigationOffset = 100;
    [].forEach.call(navigationContent, element => element.style.removeProperty('height'));
    // const navigationContentHeights = getElementAbsoluteHeights(navigationContent);
    // const navigationContentMaxHeight = Math.max.apply(null, navigationContentHeights);
    // const navigationContentOffset = navigationOffset;
    [].forEach.call(navigationContent, element => {
      // const elementHeight = navigationContentMaxHeight + navigationContentOffset;
      element.setAttribute('style', `height: auto`);
      // element.setAttribute('style', `height: ${elementHeight}px`);
    });

    // Delay menu hover
    hoverIntentHandler.add();

    navigationMenu.addEventListener('mouseleave', mouseLeaveOverlay);

    doubleTapMenu(navigationAccordionTitles, 'on');
    replaceAnchorWith(navigationMenuTitles, 'url');
  } else {
    // Reset desktop menu behaviour on resize
    [].forEach.call(navigationMenuTitles, element => element.style.removeProperty('height'));
    [].forEach.call(navigationContent, element => element.style.removeProperty('height'));
    hoverIntentHandler.remove();
    navigationMenu.removeEventListener('mouseleave', mouseLeaveOverlay);
    doubleTapMenu(navigationAccordionTitles, 'off');

    accordionHandler.add();
    replaceAnchorWith(navigationMenuTitles, 'data');
  }
}

accordionSwitch();

window.addEventListener('resize', () => {
  if (isLargeUp !== mediaQueries.is_large_up()) {
    isLargeUp = mediaQueries.is_large_up();
    accordionHandler.remove();
    if (!isLargeUp) {
      accordionHandler = accordionTransitionEffect(navigationAccordionTitles);
    }
  }
  accordionSwitch();
  usernamePadding();
});

handleTransparentHeader();
