import { connect } from 'react-redux';
import MiniProducts from 'app/commonComponents/miniProducts/miniProducts';
import { removeMiniWishlistItem } from '../miniWishlistActions';

const mapStateToProps = ({ miniWishlist }) => ({
  entries: miniWishlist.entries,
});

const mapDispatchToProps = dispatch => ({
  removeItem(entryNumber, product) {
    dispatch(removeMiniWishlistItem(product));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MiniProducts);
