import { flow, forEach, cond, chunk, unzip } from 'lodash';
import { setAttribute, addStyle, addClass, removeClass } from 'app/utils/domHelpers';
import { isSpacePressed, isTabPressed, getFocusableElements } from '../../utils/accessibility/accessibilityUtils';

function createAccordion(root, options = {}) {
  let accordionRoot = root;
  let clonedAccordionRoot = accordionRoot.cloneNode(true);
  let accordionTabs = null;
  const accordionOptions = Object.assign(
    {
      attachClickListenersManually: false,
    },
    options
  );

  return {
    init() {
      accordionTabs = chunk(accordionRoot.children, 2).map(([header, panel], index) => ({
        panel,
        header,
        triggerButton: header.children[0],
        id: `${root.id}-entry-${index}`,
        isOpen: false,
      }));
      const [headersIds, panelsIds] = unzip(
        accordionTabs.map(({ id }) => [`accordion-header-${id}`, `accordion-panel-${id}`])
      );
      initAccordionHeaders(accordionTabs, headersIds, panelsIds, accordionOptions);
      setAttributesOnPanels(accordionTabs, headersIds, panelsIds);
      handleFocusOnFocusIn(accordionRoot, accordionTabs);
      window.addEventListener('resize', onResize);
      handlePanelMutations(accordionTabs);

      return {
        isTabOpened: index => accordionTabs[index].isOpen,
        openPanel: index => openPanel(accordionTabs[index]),
        closePanel: index => closePanel(accordionTabs[index]),
        closeAll: () => accordionTabs.forEach(closePanel),
        accordionTabs,
      };
    },
    destroy() {
      accordionRoot.parentNode.replaceChild(clonedAccordionRoot, accordionRoot);
      accordionRoot = clonedAccordionRoot;
      clonedAccordionRoot = accordionRoot.cloneNode(true);
      window.removeEventListener('resize', onResize);
    },
  };

  function onResize() {
    handleAccordionOnResize(accordionTabs);
  }
}

function initAccordionHeaders(accordionTabs, headersIds, panelsIds, accordionOptions) {
  forEach(accordionTabs, (tab, index) => {
    setAttributesOnTrigger(tab.triggerButton, headersIds[index], panelsIds[index]);
    setListenersOnTrigger(accordionTabs, tab, accordionOptions);
  });
}

function setAttributesOnPanels(accordionTabs, headersIds, panelsIds) {
  forEach(accordionTabs, ({ panel }, index) => {
    flow([
      addClass('accordion-panel--collapsed'),
      setAttribute('role', 'region'),
      setAttribute('id', panelsIds[index]),
      setAttribute('aria-labelledby', headersIds[index]),
      addStyle('max-height', `${panel.scrollHeight}px`),
    ])(panel);
  });
}

function handleFocusOnFocusIn(accordionRoot, accordionTabs) {
  accordionRoot.addEventListener('focusin', e => {
    const focusableElements = getFocusableElements(accordionRoot);
    const lastFocusableElement = focusableElements.length && focusableElements[focusableElements.length - 1];
    if (
      lastFocusableElement === e.target &&
      !accordionTabs[accordionTabs.length - 1].isOpen &&
      !accordionRoot.contains(e.relatedTarget)
    ) {
      e.preventDefault();
      accordionTabs[accordionTabs.length - 1].triggerButton.focus();
    }
  });
}

function setAttributesOnTrigger(trigger, headerId, panelId) {
  flow([setAttribute('aria-expanded', 'false'), setAttribute('id', headerId), setAttribute('aria-controls', panelId)])(
    trigger
  );
}

function setListenersOnTrigger(accordionTabs, currentTab, accordionOptions) {
  currentTab.triggerButton.addEventListener(
    'click',
    () => !accordionOptions.attachClickListenersManually && headerClickHandler(currentTab, accordionOptions)
  );
  currentTab.triggerButton.addEventListener(
    'keydown',
    cond([
      [
        isSpacePressed,
        () => !accordionOptions.attachClickListenersManually && headerClickHandler(currentTab, accordionOptions),
      ],
      [isTabPressed, e => headerTabClickHandler(e, accordionTabs, currentTab)],
    ])
  );
}

function headerClickHandler(tab) {
  if (tab.isOpen) {
    closePanel(tab);
  } else {
    openPanel(tab);
  }
}

function headerTabClickHandler(e, accordionTabs, currentTab) {
  const indexOfCurrentTab = accordionTabs.indexOf(currentTab);
  const isLastTab = indexOfCurrentTab === accordionTabs.length - 1;
  const isFirstTab = indexOfCurrentTab === 0;
  const goToNextHeader = !currentTab.isOpen && !e.shiftKey && !isLastTab;
  const goToPrevHeader = e.shiftKey && !isFirstTab && !accordionTabs[indexOfCurrentTab - 1].isOpen;
  if (goToNextHeader || goToPrevHeader) {
    e.preventDefault();
    moveHeaderFocus(accordionTabs, currentTab, { moveToNext: !e.shiftKey });
  } else if (isLastTab && !currentTab.isOpen && !e.shiftKey) {
    const focusableElements = getFocusableElements(currentTab.panel);
    const lastFocusableElement = focusableElements.length && focusableElements[focusableElements.length - 1];
    if (lastFocusableElement) {
      lastFocusableElement.focus();
    }
  }
}

function openPanel(tab) {
  return new Promise(resolve => {
    tab.panel.addEventListener('transitionend', function onTransitionend() {
      resolve();
      tab.panel.removeEventListener('transitionend', onTransitionend);
      tab.isOpen = true;
    });
    flow([setAttribute('aria-expanded', 'true'), addClass('active')])(tab.triggerButton);
    removeClass('accordion-panel--collapsed', tab.panel);
  });
}

function closePanel(tab) {
  return new Promise(resolve => {
    tab.panel.addEventListener('transitionend', function onTransitionend() {
      resolve();
      tab.panel.removeEventListener('transitionend', onTransitionend);
      tab.isOpen = false;
    });
    flow([setAttribute('aria-expanded', 'false'), removeClass('active')])(tab.triggerButton);
    addClass('accordion-panel--collapsed', tab.panel);
  });
}

function moveHeaderFocus(accordionTabs, currentTab, { moveToNext }) {
  const currentTabIndex = accordionTabs.indexOf(currentTab);
  if (moveToNext) {
    accordionTabs[currentTabIndex === accordionTabs.length - 1 ? 0 : currentTabIndex + 1].triggerButton.focus();
  } else {
    accordionTabs[currentTabIndex === 0 ? accordionTabs.length - 1 : currentTabIndex - 1].triggerButton.focus();
  }
}

function handlePanelMutations(accordionTabs) {
  accordionTabs.forEach(({ panel }) => {
    const observer = new MutationObserver(() => {
      const panelMaxHeight = parseInt(panel.style.maxHeight.replace(/[^\d.]/g, ''), 10);
      if (panelMaxHeight !== panel.scrollHeight) {
        addStyle('max-height', `${panel.scrollHeight}px`, panel);
      }
    });
    observer.observe(panel, {
      attributes: true,
      childList: true,
      subtree: true,
    });
  });
}

function handleAccordionOnResize(accordionTabs) {
  accordionTabs.forEach(({ panel }) => {
    const panelMaxHeight = parseInt(panel.style.maxHeight.replace(/[^\d.]/g, ''), 10);
    if (panelMaxHeight !== panel.scrollHeight) {
      addStyle('max-height', `${panel.scrollHeight}px`, panel);
    }
  });
}

export default createAccordion;
