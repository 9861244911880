import { connect } from 'react-redux';
import MiniProducts from 'app/commonComponents/miniProducts/miniProducts';
import { removeMiniCartItem, updateMiniCartItem } from '../miniCartActions';

const mapStateToProps = ({ miniCart }) => ({
  entries: miniCart.entries,
});

const mapDispatchToProps = dispatch => ({
  removeItem(entryNumber, product) {
    dispatch(removeMiniCartItem(entryNumber, product));
  },
  updateItem(entryNumber, quantity) {
    dispatch(updateMiniCartItem(entryNumber, quantity));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MiniProducts);
