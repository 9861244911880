export const REQUEST_CART_ITEMS = 'REQUEST_CART_ITEMS';
export const RECEIVE_CART_ITEMS = 'RECEIVE_CART_ITEMS';
export const RESET_CART_VALIDATION = 'RESET_CART_VALIDATION';
export const FINISH_FETCH_CART = 'FINISH_FETCH_CART';

export const CART_ITEM_ALERT_CLASS = 'cart-item__alert';
export const CART_ITEM_NO_STOCK_ALERT_CLASS = 'cart-item__alert--no-stock';

export const SCROLL_TO_TOP_DURATION = 200;

export const ERROR_LIST = ['lowStock', 'noStock'];
export const RESET_ERRORS_FLAG = 'RESET_ERRORS_FLAG';
