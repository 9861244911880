import { debounce } from 'lodash';
import './cms-bar-component.scss';

const cmsBarComponents = document.querySelectorAll('.cms-bar-component');

cmsBarComponents.forEach(cmsBarComponent => {
  try {
    const contentTickSeconds = parseInt(cmsBarComponent.getAttribute('contenttickseconds'), 10) || 2;
    const { children } = cmsBarComponent;
    let scrollWidth = children[0]?.scrollWidth;
    let index = 0;
    let count = 0;
    const scroll = () => {
      if (index + 1 < children.length) {
        count += 10;
        if (count <= scrollWidth) {
          cmsBarComponent.scroll({ left: count });
          window.requestAnimationFrame(scroll);
        } else {
          setTimeout(() => {
            index += 1;
            scrollWidth += index * scrollWidth;
            window.requestAnimationFrame(scroll);
          }, contentTickSeconds * 1000);
        }
      } else {
        count -= children.length * 12;
        if (count >= -100) {
          cmsBarComponent.scrollTo(count, 0);
          window.requestAnimationFrame(scroll);
        } else {
          setTimeout(() => {
            const { scrollWidth: newScrollWidth } = children[0];
            scrollWidth = newScrollWidth;
            count = 0;
            index = 0;
            window.requestAnimationFrame(scroll);
          }, contentTickSeconds * 1000);
        }
      }
    };

    window.addEventListener(
      'resize',
      debounce(() => {
        const newScrollWidth = cmsBarComponent?.querySelector('div')?.scrollWidth;
        if (newScrollWidth) scrollWidth = newScrollWidth;
      }, 2000)
    );

    window.requestAnimationFrame(scroll);
  } catch (err) {
    console.error(err);
  }
});

const mobileQuickLinksShadowContainer = document.querySelector('.mobile-quick-links--shadow');

if (mobileQuickLinksShadowContainer) {
  if (mobileQuickLinksShadowContainer.children.length <= 3) {
    mobileQuickLinksShadowContainer.classList.remove('mobile-quick-links--shadow');
  } else {
    const debouncedScrollHandler = debounce(() => {
      mobileQuickLinksShadowContainer.classList.remove('mobile-quick-links--shadow');
      mobileQuickLinksShadowContainer.classList.add('mobile-quick-links--no-shadow');
    }, 750);
    mobileQuickLinksShadowContainer.addEventListener('scroll', debouncedScrollHandler);
  }
}
