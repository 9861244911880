import { CLASS_TOGGLE_DATA } from './classToggleConstants';

const classToggle = {
  bindEvents() {
    [].forEach.call(document.querySelectorAll(`[data-${CLASS_TOGGLE_DATA}]`), element => {
      element.addEventListener('click', () => {
        const $targets = document.querySelectorAll(element.dataset.jsClassToggleTarget);
        const klass = element.dataset.jsClassToggle;
        if (element.dataset.jsActiveOnly) {
          if (!element.classList.contains(klass)) {
            [].forEach.call($targets, target => {
              target.classList.toggle(klass);
            });
          }
        } else {
          [].forEach.call($targets, target => {
            target.classList.toggle(klass);
          });
        }
      });
    });
  },
};

classToggle.bindEvents();
