import React from 'react';
import PropTypes from 'prop-types';
import MiniDetails from 'app/commonComponents/miniDetails/miniDetails';
import MiniWishlistIcon from './miniWishlistIcon/miniWishlistIconContainer';
import MiniWishlistSummary from './miniWishlistSummary/miniWishlistSummaryContainer';
import MiniWishlistProducts from './miniWishlistProducts/miniWishlistProductsContainer';
import MiniWishlistOptions from './miniWishlistOptions/miniWishlistOptionsContainer';
import MiniCartHeader from '../miniCart/miniCartHeader/miniCartHeader';

const MiniWishlistApp = ({
  isOpened,
  setMiniDetailsWrapperRef,
  miniRef,
  closeOrRedirect,
  openOrRedirect,
  keyboardToggle,
  cmsComponents,
  backdrop,
  miniType,
  baseUrl,
}) => (
  <div className="nav__btn-wrapper" ref={miniRef}>
    <MiniWishlistIcon
      miniType={miniType}
      isOpened={isOpened}
      openOrRedirect={openOrRedirect}
      keyboardToggle={keyboardToggle}
      baseUrl={baseUrl}
    />
    {isOpened && (
      <MiniDetails
        backdrop={backdrop}
        setMiniDetailsWrapperRef={setMiniDetailsWrapperRef}
      >
        <MiniCartHeader miniType={miniType} />
        <MiniWishlistSummary closeOrRedirect={closeOrRedirect} />
        <MiniWishlistProducts shoppingList={cmsComponents.shoppingList} />
        <MiniWishlistOptions
          miniType={miniType}
          retrieveBag={cmsComponents.retrieveBag}
          closeOrRedirect={closeOrRedirect}
        />
      </MiniDetails>
    )}
  </div>
);

MiniWishlistApp.propTypes = {
  isOpened: PropTypes.bool.isRequired,
  miniRef: PropTypes.objectOf(PropTypes.object).isRequired,
  miniType: PropTypes.string.isRequired,
  cmsComponents: PropTypes.objectOf(PropTypes.instanceOf(Element)).isRequired,
  backdrop: PropTypes.objectOf(PropTypes.object).isRequired,
  setMiniDetailsWrapperRef: PropTypes.func.isRequired,
  closeOrRedirect: PropTypes.func.isRequired,
  openOrRedirect: PropTypes.func.isRequired,
  keyboardToggle: PropTypes.func.isRequired,
  baseUrl: PropTypes.string.isRequired,
};

export default MiniWishlistApp;
