import React, { Component } from 'react';
import PropTypes from 'prop-types';

class MiniDetails extends Component {
  constructor() {
    super();
    this.miniDetailsRef = React.createRef();
    this.miniDetailsWrapperRef = React.createRef();
  }

  componentDidMount() {
    const { setMiniDetailsWrapperRef } = this.props;
    setMiniDetailsWrapperRef(this.miniDetailsWrapperRef);
  }

  render() {
    const { children } = this.props;

    return (
      <div role="alert" className="mini__details-wrapper" ref={this.miniDetailsWrapperRef}>
        <div className="mini__details" ref={this.miniDetailsRef}>
          {children}
        </div>
      </div>
    );
  }
}

MiniDetails.propTypes = {
  setMiniDetailsWrapperRef: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.element, PropTypes.string]).isRequired,
};

export default MiniDetails;
