import React from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import Checkbox from '../atoms/checkbox';
import Button from '../atoms/button';
import Field from '../atoms/input';
import ErrorMessage from '../atoms/errorMessage';

const SubscriptionCoupon = ({
  handleInputChange,
  onSubmit,
  rootBEM,
  rootMessages,
  buttonText,
  formName,
  validateGender,
  errors,
  responseError = null,
  handleEmailChange = null,
}) => {
  const intl = useIntl();
  return (
    <form className={`${rootBEM}__form`} name={formName}>
      <div className={`${rootBEM}__item-email`}>
        <label htmlFor={`${rootBEM}-email`}>
          {intl.formatMessage({
            id: `${rootMessages}.email.label`,
          })}
        </label>
        <Field
          type="email"
          name={`${rootBEM}-email`}
          id={`${rootBEM}-email`}
          aria-label={intl.formatMessage({
            id: `${rootMessages}.email.placeholder`,
          })}
          placeholder={intl.formatMessage({
            id: `${rootMessages}.email.placeholder`,
          })}
          className={`${rootBEM}__item-email-input`}
          onChange={handleEmailChange}
        />
      </div>

      <div className={`${rootBEM}__item-gender-button`}>
        <div className={`${rootBEM}__item-gender`}>
          <p className={`${rootBEM}__item-gender-label`}>
            {intl.formatMessage({
              id: `${rootMessages}.shoppingInterests`,
              defaultMessage: 'Shopping interests',
            })}
          </p>
          <div className={`${rootBEM}__item-gender-one`}>
            <Checkbox
              checkboxName={`${rootBEM}-women`}
              checkboxInputClass={`${rootBEM}__item-gender-checkbox`}
              checkboxLabelId={`${rootMessages}.gender.women`}
              checkboxOnChange={handleInputChange}
              validate={validateGender}
            />
          </div>
          <div className={`${rootBEM}__item-gender-two`}>
            <Checkbox
              checkboxName={`${rootBEM}-men`}
              checkboxInputClass={`${rootBEM}__item-gender-checkbox`}
              checkboxLabelId={`${rootMessages}.gender.men`}
              checkboxOnChange={handleInputChange}
              validate={validateGender}
            />
          </div>
        </div>

        <div className={`${rootBEM}__item-button`}>
          <Button
            buttonOnClick={onSubmit}
            buttonText={buttonText}
            buttonClassName="expand no-margin-top no-margin-bottom"
          />
        </div>
      </div>

      <div className={`${rootBEM}__item-error`}>
        <ErrorMessage name={`${rootBEM}-email`} />
        <ErrorMessage name={`${rootBEM}-women`} />
        {!errors[`${rootBEM}-women`] && <ErrorMessage name={`${rootBEM}-men`} />}
        {responseError && (
          <span className="form-msg-error" role="alert">
            {responseError}
          </span>
        )}
      </div>
    </form>
  );
};

SubscriptionCoupon.defaultProps = {
  responseError: '',
  handleEmailChange: null,
};

SubscriptionCoupon.propTypes = {
  handleInputChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  rootBEM: PropTypes.string.isRequired,
  rootMessages: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  formName: PropTypes.string.isRequired,
  validateGender: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  responseError: PropTypes.string,
  handleEmailChange: PropTypes.func,
};

export default SubscriptionCoupon;
