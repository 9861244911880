import React from 'react';

export const CloseIcon = ({ onClick, className }) => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    onClick={event => onClick?.(event)}
    className={className}
  >
    <path
      d="M15.7656 15.3125C15.4844 15.5938 14.9688 15.5938 14.6875 15.3125L8.5 9.07812L2.26562 15.3125C1.98438 15.5938 1.46875 15.5938 1.1875 15.3125C0.90625 15.0312 0.90625 14.5156 1.1875 14.2344L7.42188 8L1.1875 1.8125C0.90625 1.53125 0.90625 1.01562 1.1875 0.734375C1.46875 0.453125 1.98438 0.453125 2.26562 0.734375L8.5 6.96875L14.6875 0.734375C14.9688 0.453125 15.4844 0.453125 15.7656 0.734375C16.0469 1.01562 16.0469 1.53125 15.7656 1.8125L9.53125 8L15.7656 14.2344C16.0469 14.5156 16.0469 15.0312 15.7656 15.3125Z"
      fill="#1F1F1F"
    />
  </svg>
);

export const ProfileIcon = ({ className }) => (
  <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path
      d="M9 10C6.22656 10 4 7.77344 4 5C4 2.26562 6.22656 0 9 0C11.7344 0 14 2.26562 14 5C14 7.77344 11.7344 10 9 10ZM7.20312 11.875H10.7578C14.625 11.875 17.75 15 17.75 18.8672C17.75 19.4922 17.2031 20 16.5781 20H1.38281C0.757812 20 0.25 19.4922 0.25 18.8672C0.25 15 3.33594 11.875 7.20312 11.875Z"
      fill="#1F1F1F"
    />
  </svg>
);
