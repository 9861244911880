import { flashWarningMessage } from 'app/globalMessages/globalMessagesClient';

const { accountLogoutUrl } = window.inlineGlobalConfig;

async function accountLogout(e) {
  e.preventDefault();
  flashWarningMessage('ui.general.logout.notice');
  window.location.href = accountLogoutUrl;
}

export default accountLogout;
