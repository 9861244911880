import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { hideMessage } from '../../../globalMessages/globalMessagesClient';
import { CustomModalWrapper } from './CustomModalWrapper';
import { HIGHEST_MESSAGE_PRIORITY } from '../../../globalMessages/globalMessagesConstants';
import { useExchangeDelete } from '../../../exchange/services/useExchangeDelete';
import { redirectTo } from '../../../utils/redirects';
import { useExchangeMessage } from './hooks/useExchangeMessage';

const { homeUrl } = window.inlineCartConfiguration;

const {
  locale: { country },
} = window.inlineGlobalConfig;

export const ExchangeMessage = ({ miniCart }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = () => setIsModalOpen(prevState => !prevState);

  const { exchangeDeleteRequest } = useExchangeDelete();

  // TODO refactor not to use headerMiniHOC, but pass directly from miniCartApp

  const messageDescriptor = {
    message: `<span>Your basket has <b>${miniCart.exchange?.amount}</b> ${
      country === 'us' ? '(excl taxes)' : ''
    } credit from an exchange</span>`,
    customCloseButton: (
      <button className="exchange-close-button" type="button" onClick={() => toggleModal()}>
        Cancel this exchange
      </button>
    ),
    customClass: 'exchange-banner',
    displayTime: 0,
    priority: HIGHEST_MESSAGE_PRIORITY,
  };

  useExchangeMessage({ miniCart, messageDescriptor });

  if (!isModalOpen) {
    return null;
  }

  return (
    <>
      {isModalOpen && (
        <CustomModalWrapper
          isModalOpen={isModalOpen}
          maxWidth={600}
          modalToggle={toggleModal}
          titleMsg="Are you sure you want to cancel this exchange?"
          customParentClass="cancel-exchange-modal"
          isForbidClose
        >
          <p>
            If you cancel this exchange, you’ll be taken out of the exchange process and your exchange credit will be
            removed from your basket.
          </p>
          <p>To restore your credit, you’ll need to go back through the exchange portal.</p>
          <div className="button-container">
            <button
              type="button"
              onClick={() => {
                exchangeDeleteRequest();
                redirectTo(homeUrl);
                hideMessage();
                toggleModal();
              }}
            >
              Cancel exchange
            </button>
            <button className="button-container__close" onClick={() => toggleModal()} type="button">
              <FormattedMessage id="products.productgrid.close" description="Close"/>
            </button>
          </div>
        </CustomModalWrapper>
      )}
    </>
  );
};
