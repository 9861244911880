import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setGlobalWishlistInfo } from 'app/utils/globalDataHelpers';
import headerMiniHOC from '../headerMiniHOC';
import MiniWishlistApp from './miniWishlistApp';
import { getMiniWishlist, updateMiniWishlist } from './miniWishlistActions';
import {
  MINI_WISHLIST_ROOT_ELEMENT,
  TRANSFER_MINI_WISHLIST_ADD,
  TRANSFER_MINI_WISHLIST_REMOVE,
} from './miniWishlistConstants';

const { baseWishlistUrl, pageType } = window.inlineGlobalConfig;

const WISHLIST_PAGE_TYPE = 'wishlist';

const miniWishlistConfig = WrappedComponent => {
  const DecoratedComponent = props => {
    const { fetchMini, updateMini } = props;
    const afterFetch = useCallback(
      wishListData => {
        setGlobalWishlistInfo(wishListData);
        MINI_WISHLIST_ROOT_ELEMENT.addEventListener(TRANSFER_MINI_WISHLIST_REMOVE, e => updateMini(e.detail));
      },
      [updateMini]
    );

    useEffect(() => {
      if (pageType === WISHLIST_PAGE_TYPE) {
        fetchMini().then(afterFetch);
      }
    }, [fetchMini, afterFetch]);

    return (
      <WrappedComponent
        miniConfig={{
          miniRootElement: MINI_WISHLIST_ROOT_ELEMENT,
          transferMiniAdd: TRANSFER_MINI_WISHLIST_ADD,
          baseUrl: baseWishlistUrl,
          pageType: WISHLIST_PAGE_TYPE,
          cmsComponents: {
            shoppingList: document.querySelector('.mini-wishlist__shopping-list'),
            retrieveBag: document.querySelector('.mini-wishlist__retrieve-wishlist'),
          },
          afterFetch,
        }}
        {...props}
      />
    );
  };

  DecoratedComponent.propTypes = {
    fetchMini: PropTypes.func.isRequired,
    updateMini: PropTypes.func.isRequired,
  };

  return DecoratedComponent;
};

const mapDispatchToProps = dispatch => ({
  fetchMini() {
    return dispatch(getMiniWishlist());
  },
  updateMini(json) {
    dispatch(updateMiniWishlist(json));
  },
});

export default connect(
  null,
  mapDispatchToProps
)(miniWishlistConfig(headerMiniHOC(MiniWishlistApp)));
