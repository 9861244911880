import { isEmpty } from 'lodash';
import { ajaxRequest } from 'app/utils/helpers';
import { setGlobalWishlistInfo } from 'app/utils/globalDataHelpers';
import { dlRemoveFromWishlist } from 'app/analytics/wishlistAnalytics';
import { slideProductUp } from '../headerMiniUtils';
import {
  RECEIVE_MINI_WISHLIST,
  MINI_WISHLIST_ROOT_ELEMENT,
  TRANSFER_MINI_WISHLIST_REMOVE,
} from './miniWishlistConstants';

const { getWishlistUrl, removeFromWishlistUrl } = window.inlineGlobalConfig;

export const receiveMiniWishlist = (
  json = {
    id: null,
    entries: [],
    totalItems: 0,
  }
) => ({
  type: RECEIVE_MINI_WISHLIST,
  id: json.name,
  entries: json.entries,
  totalItems: json.entries.length,
});

const executeAjaxRequest =
  ({ method, url }, animateProduct) =>
  dispatch =>
    ajaxRequest(method, url)
      .then(json => (animateProduct ? animateProduct(json) : json))
      .then(json => setGlobalWishlistInfo(json))
      .then(json => {
        dispatch(json && !isEmpty(json.wishlist) ? receiveMiniWishlist(json.wishlist) : receiveMiniWishlist());
        return json;
      })
      .catch(err => {
        dispatch(receiveMiniWishlist());
        console.error(err);
      });

export const getMiniWishlist = () => dispatch =>
  dispatch(
    executeAjaxRequest({
      method: 'GET',
      url: getWishlistUrl,
    })
  );

// TODO Wishlist is not being updated when product removed from mini wishlist
export const removeMiniWishlistItem = product => dispatch => {
  const animateSlideUp = slideProductUp(product.id, MINI_WISHLIST_ROOT_ELEMENT);
  return dispatch(
    executeAjaxRequest(
      {
        method: 'DELETE',
        url: `${removeFromWishlistUrl}/${product.id}`,
      },
      animateSlideUp
    )
  )
    .then(response => {
      MINI_WISHLIST_ROOT_ELEMENT.dispatchEvent(new CustomEvent(TRANSFER_MINI_WISHLIST_REMOVE, { detail: response }));
      dlRemoveFromWishlist({ product, location: 'mini wishlist' });
    })
    .catch(error => {
      dlRemoveFromWishlist({
        product,
        location: 'mini wishlist',
        operationResult: JSON.stringify(error),
      });
    });
};

export const updateMiniWishlist = response => dispatch =>
  setGlobalWishlistInfo(response).then(json =>
    dispatch(json && !isEmpty(json.wishlist) ? receiveMiniWishlist(json.wishlist) : receiveMiniWishlist())
  );
