import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const MiniWishlistSummary = ({ totalItems }) => (
  <div className={`mini__summary ${!totalItems ? 'mini__summary--empty' : ''}`}>
    {totalItems ? (
      <div className="mini__summary-wrapper">
        <div className="mini__summary-info">
          <p className="mini__summary-info-bold">
            <FormattedMessage id="header.navigation.wishlist.label" description="Wishlist" />
          </p>
          <p>
            <strong>{totalItems}</strong>{' '}
            {totalItems === 1 ? (
              <FormattedMessage id="cart.summary.count.item" description="Item" />
            ) : (
              <FormattedMessage id="cart.summary.count.items" description="Items" />
            )}
          </p>
        </div>
      </div>
    ) : (
      <p className="mini__summary-empty-bag">
        <FormattedMessage id="wishlist.empty.label" description="Empty wishlist" />
      </p>
    )}
  </div>
);

MiniWishlistSummary.propTypes = {
  totalItems: PropTypes.number.isRequired,
};

export default MiniWishlistSummary;
