import React from 'react';
import providerHOC from 'app/utils/providerHOC/providerHOC';
import TrustPilotCarousel from './trustpilotReviews';

const TrustPilotCarouselContainer = () => {
  const { totalReviews, trustpilotScore } = window.trustpilotReviews;
  const reviews = JSON.parse(document.querySelector('#trustpilot-reviews').getAttribute('data-reviews'));

  if (!reviews) {
    return null;
  }

  return <TrustPilotCarousel reviews={reviews} totalReviews={totalReviews} trustpilotScore={trustpilotScore} />;
};

export default providerHOC()(TrustPilotCarouselContainer);
