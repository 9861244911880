import mediaQueries from 'app/utils/mediaQueries';

const getMediaRuleFor = (id, ratio) => `#${id}:before { padding-top: ${ratio}%; }`;

const buttonsBannerComponents = document.querySelectorAll('.buttons-banner-component');

const updateButtonsBanners = [...buttonsBannerComponents].map(buttonsBannerComponent => {
  const bannerId = buttonsBannerComponent.id;
  const mediaWidth = parseInt(buttonsBannerComponent.dataset.mediaWidth, 10);
  const mediaHeight = parseInt(buttonsBannerComponent.dataset.mediaHeight, 10);

  const PERCENTAGE_100 = 100;
  const ASPECT_RATIO_RULE_INDEX = 0;

  const styleSheet = [...document.styleSheets].find(stylesheet => stylesheet.ownerNode.dataset.id === bannerId);

  buttonsBannerComponent.classList.remove('hidden');

  const updateAspectRatioPadding = () => {
    const imagePadding = mediaWidth > 0 && mediaHeight > 0 ? (mediaHeight / mediaWidth) * PERCENTAGE_100 : 0;
    const copyOuterPaddingTop = parseInt(
      window
        .getComputedStyle(buttonsBannerComponent.querySelector('.media-banner__wrapper'))
        .getPropertyValue('padding-top'),
      10
    );
    const copyOuterPaddingBottom = parseInt(
      window
        .getComputedStyle(buttonsBannerComponent.querySelector('.media-banner__wrapper'))
        .getPropertyValue('padding-bottom'),
      10
    );
    const copyOuterPadding = copyOuterPaddingTop + copyOuterPaddingBottom;
    const copyHeight = buttonsBannerComponent.querySelector('.media-buttons-banner__copy').offsetHeight;
    const copyWidth = buttonsBannerComponent.querySelector('.media-buttons-banner__copy').offsetWidth;

    const copyPadding = ((copyHeight + copyOuterPadding) / (copyWidth + copyOuterPadding)) * PERCENTAGE_100;

    const bannerPadding =
      !mediaQueries.is_xlarge_up() || (mediaQueries.is_xlarge_up() && copyPadding > imagePadding)
        ? copyPadding
        : imagePadding;

    styleSheet.deleteRule(ASPECT_RATIO_RULE_INDEX);
    styleSheet.insertRule(getMediaRuleFor(bannerId, bannerPadding), ASPECT_RATIO_RULE_INDEX);
  };

  updateAspectRatioPadding();

  return updateAspectRatioPadding;
});

if (buttonsBannerComponents.length) {
  window.addEventListener('resize', () => updateButtonsBanners.forEach(updateButtonsBanner => updateButtonsBanner()));
}
