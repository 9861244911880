import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { fetchUserInfo } from 'app/utils/loggedUserInfoHOC/loggedUserInfoActions';
import accountLogout from 'app/utils/logout';
import './csa-banner.scss';

const { accountLogoutUrl } = window.inlineGlobalConfig;

const CSABannerApp = ({ fetchLoggedUserInfo, isFetching, csAgentIsLogged, csAgentUsername, csAgentCartId }) => {
  useEffect(() => {
    fetchLoggedUserInfo();
  }, [fetchLoggedUserInfo]);

  if (isFetching || !csAgentIsLogged) {
    return null;
  }

  return (
    <div className="csa-banner">
      <p className="csa-banner__content">
        <FormattedMessage id="account.csaBanner.loggedInCustomer" /> <strong>{csAgentUsername}</strong>{' '}
        <FormattedMessage id="account.csaBanner.cartId" /> <strong>{csAgentCartId}</strong>{' '}
        <a href={accountLogoutUrl} onClick={accountLogout}>
          <FormattedMessage id="account.csa.logout" />
        </a>
      </p>
    </div>
  );
};

CSABannerApp.defaultProps = {
  csAgentUsername: '',
  csAgentCartId: '',
  csAgentIsLogged: false,
  isFetching: false,
};

CSABannerApp.propTypes = {
  csAgentUsername: PropTypes.string,
  csAgentCartId: PropTypes.string,
  csAgentIsLogged: PropTypes.bool,
  isFetching: PropTypes.bool,
  fetchLoggedUserInfo: PropTypes.func.isRequired,
};

const mapStateToProps = ({ loggedUserInfo }) => ({
  csAgentUsername: loggedUserInfo.csAgentUsername,
  csAgentCartId: loggedUserInfo.csAgentCartId,
  csAgentIsLogged: loggedUserInfo.csAgentIsLogged,
  isFetching: loggedUserInfo.isFetching,
});

const mapDispatchToProps = dispatch => ({
  fetchLoggedUserInfo() {
    dispatch(fetchUserInfo());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CSABannerApp);
