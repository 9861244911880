export const round = number => {
  switch (true) {
    case number < 1.3:
      return { rating: 1, humanReadableRating: 'bad' };
    case number >= 1.3 && number < 1.8:
      return { rating: 1.5, humanReadableRating: 'bad' };
    case number >= 1.8 && number < 2.3:
      return { rating: 2, humanReadableRating: 'poor' };
    case number >= 2.3 && number < 2.8:
      return { rating: 2.5, humanReadableRating: 'poor' };
    case number >= 2.8 && number < 3.3:
      return { rating: 3, humanReadableRating: 'average' };
    case number >= 3.3 && number < 3.8:
      return { rating: 3.5, humanReadableRating: 'average' };
    case number >= 3.8 && number < 4.3:
      return { rating: 4, humanReadableRating: 'great' };
    case number >= 4.3 && number < 4.8:
      return { rating: 4.5, humanReadableRating: 'excellent' };
    case number >= 4.8 && number <= 5:
      return { rating: 5, humanReadableRating: 'excellent' };
    default:
      return { rating: 5, humanReadableRating: 'excellent' };
  }
};

export const getStarLink = rating => `https://cdn.trustpilot.net/brand-assets/4.1.0/stars/stars-${rating}.svg`;
