import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isSpacePressed, isEscPressed } from 'app/utils/accessibility/accessibilityUtils';
import mediaQueries from 'app/utils/mediaQueries';
import { topWrapperOffset, transitionEndEvent } from 'app/utils/helpers';
import { hoverIntent } from '../cmscomponents/header/hoverIntent';
import { CLOSE_SEARCH, CLOSE_NAVIGATION } from '../cmscomponents/header/headerConstants';
import { HEADER_FADE_SPEED, HEADER_MINI_CLOSE_ALL } from './headerConstants';

const { pageType } = window.inlineGlobalConfig;

const headerMiniHOC = WrappedComponent => {
  class MiniHOC extends Component {
    static animateDetails({ type, detailsElement, headerBackdrop }) {
      if (!detailsElement) {
        return Promise.resolve();
      }

      const ANIMATION = {
        in() {
          headerBackdrop.classList.add('header__backdrop--animate');
        },
        out() {
          headerBackdrop.classList.remove('header__backdrop--animate');
        },
      };

      ANIMATION[type]();

      return transitionEndEvent(headerBackdrop);
    }

    constructor(props) {
      super(props);

      this.state = {
        isOpened: false,
        isOpening: false,
        isClosing: false,
        topOffset: null,
      };

      this.headerBackdrop = document.querySelector('.header__backdrop');

      this.config = props.miniConfig;

      this.miniDetailsWrapperRef = null;
      this.miniRef = React.createRef();

      this.setMiniDetailsWrapperRef = this.setMiniDetailsWrapperRef.bind(this);

      this.openDetails = this.openDetails.bind(this);
      this.closeDetails = this.closeDetails.bind(this);

      this.openOrRedirect = this.openOrRedirect.bind(this);
      this.closeOrRedirect = this.closeOrRedirect.bind(this);
      this.keyboardToggle = this.keyboardToggle.bind(this);
    }

    componentDidMount() {
      const { fetchMini, updateMini } = this.props;

      if (pageType !== this.config.pageType) {
        fetchMini().then(this.config.afterFetch);
      }

      hoverIntent(
        [this.miniRef.current],
        () => {
          if (!mediaQueries.is_large_up()) {
            return;
          }
          this.openDetails();
        },
        () => {
          if (!mediaQueries.is_large_up()) {
            return;
          }
          this.closeDetails();
        }
      ).add();

      this.config.miniRootElement.addEventListener(this.config.transferMiniAdd, e => {
        updateMini(e.detail);
      });

      document.addEventListener(
        HEADER_MINI_CLOSE_ALL,
        () => {
          if (this.miniDetailsWrapperRef !== null && this.miniDetailsWrapperRef.current !== null) {
            this.closeDetails();
          }
        },
        false
      );

      document.addEventListener(
        'focus',
        e => {
          if (
            this.miniDetailsWrapperRef !== null &&
            this.miniDetailsWrapperRef.current !== null &&
            this.miniRef !== null &&
            this.miniRef.current !== null
          ) {
            const elInsideFocusableDetailWrapper = this.miniDetailsWrapperRef.current.contains(e.target);
            const elInsideFocusableIcon = this.miniRef.current.contains(e.target);
            if (!elInsideFocusableDetailWrapper && !elInsideFocusableIcon) {
              this.closeDetails();
            }
          }
        },
        true
      );

      document.addEventListener('keydown', e => {
        if (isEscPressed(e)) {
          if (this.miniDetailsWrapperRef !== null && this.miniDetailsWrapperRef.current !== null) {
            this.closeDetails();
          }
        }
      });
    }

    setMiniDetailsWrapperRef(ref) {
      this.miniDetailsWrapperRef = ref;
    }

    closeDetails() {
      const { isClosing, topOffset } = this.state;

      if (isClosing) {
        return;
      }

      this.setState(
        {
          isClosing: true,
        },
        () => {
          const isLargeUp = mediaQueries.is_large_up();

          this.config.miniRootElement.classList.remove('is-active');

          if (!isLargeUp) {
            topWrapperOffset('close', topOffset, 'no-overlay-scroll');
          }

          MiniHOC.animateDetails({
            type: 'out',
            detailsElement: this.miniDetailsWrapperRef.current,
            headerBackdrop: this.headerBackdrop,
          }).then(() => {
            this.setState({
              isClosing: false,
              isOpened: false,
            });
          });
        }
      );
    }

    openDetails() {
      const { isOpening, isOpened } = this.state;

      if (isOpening || isOpened) {
        return;
      }

      this.setState(
        {
          isOpened: true,
          isOpening: true,
        },
        () => {
          const isLargeUp = mediaQueries.is_large_up();

          const searchPanel = document.querySelector('.global-header__search-container');
          const isSearchOpened = searchPanel.classList.contains('is-active');
          if (isSearchOpened) {
            searchPanel.dispatchEvent(new CustomEvent(CLOSE_SEARCH));
          }
          document.dispatchEvent(new CustomEvent(CLOSE_NAVIGATION));

          this.config.miniRootElement.classList.add('is-active');

          if (!isLargeUp) {
            const topOffset = window.pageYOffset;
            this.setState({ topOffset });
            topWrapperOffset('open', topOffset, 'no-overlay-scroll');
          }

          MiniHOC.animateDetails({
            type: 'in',
            detailsElement: this.miniDetailsWrapperRef.current,
            headerBackdrop: this.headerBackdrop,
          }).then(() => {
            this.setState({
              isOpening: false,
            });
          });
        }
      );
    }

    closeOrRedirect() {
      if (pageType === this.config.pageType) {
        return;
      }
      if (pageType !== this.config.pageType) {
        window.location = this.config.baseUrl;
      } else {
        this.closeDetails();
      }
    }

    openOrRedirect(e) {
      const { isOpened } = this.state;
      const isLargeUp = mediaQueries.is_large_up();
      if (isOpened) {
        window.location = this.config.baseUrl;
      } else if (!isLargeUp) {
        e.preventDefault();
        this.closeOrRedirect();
      }
    }

    keyboardToggle(e) {
      if (isSpacePressed(e)) {
        e.preventDefault();
        document.dispatchEvent(new CustomEvent(HEADER_MINI_CLOSE_ALL));
        this.headerBackdrop.classList.remove('header__backdrop--animate');
        this.openDetails();
      }
    }

    render() {
      const { isOpened } = this.state;

      return (
        <WrappedComponent
          isOpened={isOpened}
          fadeSpeed={HEADER_FADE_SPEED}
          backdrop={this.headerBackdrop}
          cmsComponents={this.config.cmsComponents}
          miniRef={this.miniRef}
          miniType={this.config.pageType}
          setMiniDetailsWrapperRef={this.setMiniDetailsWrapperRef}
          closeOrRedirect={this.closeOrRedirect}
          openOrRedirect={this.openOrRedirect}
          keyboardToggle={this.keyboardToggle}
          baseUrl={this.config.baseUrl}
        />
      );
    }
  }

  MiniHOC.propTypes = {
    fetchMini: PropTypes.func.isRequired,
    updateMini: PropTypes.func.isRequired,
    miniConfig: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.func]))
      .isRequired,
  };

  return MiniHOC;
};

export default headerMiniHOC;
