import React, { Component } from 'react';
import { createRoot } from 'react-dom/client';
import providerHOC from 'app/utils/providerHOC/providerHOC';
import mediaQueries from 'app/utils/mediaQueries';
import { getBaseStoreData } from 'app/utils/globalDataHelpers';
import { topWrapperOffset } from 'app/utils/helpers';
import SiteSelectorButton from './siteSelectorButton';
import SiteSelectorModal from './siteSelectorModal';
import './site-selector.scss';

const { locale } = window.inlineGlobalConfig;

class SiteSelector extends Component {
  constructor() {
    super();

    this.state = {
      isModalOpen: false,
      topOffset: null,
      baseStore: {
        stores: [],
      },
    };

    this.toggleModal = this.toggleModal.bind(this);
  }

  componentDidMount() {
    getBaseStoreData().then(baseStore => {
      this.setState({
        baseStore,
      });
    });
  }

  toggleModal() {
    const { topOffset } = this.state;
    this.setState(
      prevState => ({
        isModalOpen: !prevState.isModalOpen,
      }),
      () => {
        if (!mediaQueries.is_large_up()) {
          if (!document.querySelector('html').classList.contains('site-selector__no-scroll')) {
            this.setState(
              {
                topOffset: window.pageYOffset,
              },
              () => topWrapperOffset('open', topOffset, 'site-selector__no-scroll')
            );
          } else {
            topWrapperOffset('close', topOffset, 'site-selector__no-scroll');
          }
        }
      }
    );
  }

  render() {
    const { isModalOpen, baseStore } = this.state;

    return (
      <div>
        <SiteSelectorButton locale={locale} modalToggle={this.toggleModal} availableStores={baseStore} />
        {isModalOpen && (
          <SiteSelectorModal
            locale={locale}
            modalToggle={this.toggleModal}
            isModalOpen={isModalOpen}
            availableStores={baseStore}
          />
        )}
      </div>
    );
  }
}

const SiteSelectorRoot = providerHOC()(SiteSelector);
createRoot(document.getElementById('siteSelector')).render(<SiteSelectorRoot />);
