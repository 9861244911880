import * as utils from './gtmAnalyticsUtils';

const observer = new IntersectionObserver(onIntersection, {
  threshold: 0.5,
});

function onIntersection(entries) {
  const visibleBanners = [];

  entries.forEach(entry => {
    try {
      if (entry.isIntersecting) {
        const currentElement = entry.target;
        observer.unobserve(currentElement);

        const imageElement = currentElement.querySelector('img');
        const linksButtons = currentElement.querySelectorAll('.js-tracking-link-buttons');

        if (imageElement) {
          const imageSource = imageElement.src;
          const bannerId = utils.replaceSpecialChars(
            imageSource.substr(imageSource.lastIndexOf('/') + 1).split('?')[0]
          );
          const bannerHeadline1 = utils.replaceSpecialChars(
            utils.checkForTextExistence(currentElement.querySelector('.js-tracking-title1'), 'title1')
          );
          const bannerHeadline2 = utils.replaceSpecialChars(
            utils.checkForTextExistence(currentElement.querySelector('.js-tracking-title1'), 'title2')
          );
          const bannerCTA = utils.replaceSpecialChars(
            utils.checkForTextExistence(currentElement.querySelector('.media-banner__cta'), 'cta')
          );
          const bannerPosition = utils.positionRemapping(
            utils.checkForTextExistence(currentElement.querySelector('.js-tracking-position'), 'position')
          );
          const bannerSlotName = utils.replaceSpecialChars(utils.findParentRowComponent(currentElement));
          const bannerCategoryType = utils.replaceSpecialChars(currentElement.dataset.categoryType);
          const bannerCategoryName = utils.replaceSpecialChars(currentElement.dataset.categoryName);
          const linksButtonsCTAs =
            linksButtons.length &&
            [...linksButtons].map(button => utils.replaceSpecialChars(button.dataset.titleen)).join('/');

          const layer = {
            id: bannerId,
            name: `${bannerCategoryType || ''}/${bannerCategoryName || ''}`,
            creative: `${bannerHeadline1 || ''}${bannerHeadline2 ? ` ${bannerHeadline2}` : ''}/${
              bannerCTA || linksButtonsCTAs || ''
            }`.trim(),
            position: `${bannerSlotName || ''}/${bannerPosition || ''}`,
          };

          visibleBanners.push(layer);

          [].forEach.call(linksButtons.length ? linksButtons : [currentElement], element => {
            element.addEventListener('click', () => {
              window.dataLayer.push({
                event: 'visitor interaction',
                interaction_name: 'promo click',
                ecommerce: {
                  promoClick: {
                    promotions: [layer],
                  },
                },
              });
            });
          });
        }
      }
    } catch (e) {
      console.error(e);
    }
  });

  if (visibleBanners.length) {
    window.dataLayer.push({
      event: 'visitor interaction',
      interaction_name: 'promo impression',
      ecommerce: {
        promoView: {
          promotions: visibleBanners,
        },
      },
    });
  }
}

export default function dlTrackMediaBannersEvents() {
  const mediaBanners = document.querySelectorAll('.js-tracking-media-banners:not([data-analytics-attached])');
  [...mediaBanners].forEach(banner => {
    observer.observe(banner);
    banner.setAttribute('data-analytics-attached', true);
  });
}
