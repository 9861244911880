import { connect } from 'react-redux';
import MiniCartOptions from './miniCartOptions';

// TODO refactor this to use selectors
const mapStateToProps = ({ miniCart, loggedUserInfo }) => ({
  entries: miniCart.entries,
  exchange: miniCart.exchange,
  subtotal: miniCart.subtotal,
  subTotalWithoutDiscounts: miniCart.subTotalWithoutDiscounts,
  totalDiscounts: miniCart.totalDiscounts,
  accountIsLogged: loggedUserInfo.accountIsLogged,
});

export default connect(mapStateToProps)(MiniCartOptions);
