import { getLocaleDataByUid, getLanguageName } from './gtmAnalyticsUtils';

export function dlTrackSiteSwitch({ country, language }) {
  window.dataLayer.push({
    event: 'visitor interaction',
    interaction_name: 'site switch',
    site_switch_country: getLocaleDataByUid(country.toLowerCase()).country,
    site_switch_language: getLanguageName(language.split('_')[0].toLowerCase()),
  });
}
