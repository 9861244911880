import React from 'react';
import { FormattedMessage } from 'react-intl';

export const PROMO_ERROR_MSG = 'order.summary.promo.error.msg';

export const PROMO_ERROR_MSG_MAP = {
  'coupon.validation.restriction.fail.customerSubscriptionRestriction':
    'security.ui.auth.couponDiscount.promptToSubscribe.message',
  'coupon.validation.restriction.fail.customerStoreSpecificOrderRestriction': 'security.ui.auth.couponDiscount.used',
  'coupon.invalid.code.provided': PROMO_ERROR_MSG,
};

export const PROMO_STATUSES = {
  NO_PROMO_APPLIED: 'NO_PROMO_APPLIED',
  PROMO_INPUT_BOX: 'PROMO_INPUT_BOX',
  PROMO_INPUT_BOX_PROGRESS: 'PROMO_INPUT_BOX_PROGRESS',
  INVALID_PROMO: 'INVALID_PROMO',
  PROMO_APPLIED: 'PROMO_APPLIED',
};

export const PROMO_STATE = {
  [PROMO_STATUSES.NO_PROMO_APPLIED]: {
    code: PROMO_STATUSES.NO_PROMO_APPLIED,
    showPromoInput: false,
  },
  [PROMO_STATUSES.PROMO_INPUT_BOX]: {
    code: PROMO_STATUSES.PROMO_INPUT_BOX,
    showPromoInput: true,
    buttonText: <FormattedMessage id="order.summary.promo.apply.cta" />,
    isCtaDisabled: false,
    isInputDisabled: false,
    className: 'cart-order-summary__promo-input',
  },
  [PROMO_STATUSES.PROMO_INPUT_BOX_PROGRESS]: {
    code: PROMO_STATUSES.PROMO_INPUT_BOX_PROGRESS,
    showPromoInput: true,
    buttonText: <FormattedMessage id="order.summary.promo.applying.cta" />,
    isCtaDisabled: true,
    isInputDisabled: true,
    className: 'cart-order-summary__promo-input ',
  },
  [PROMO_STATUSES.INVALID_PROMO]: {
    code: PROMO_STATUSES.INVALID_PROMO,
    buttonText: <FormattedMessage id="order.summary.promo.apply.cta" />,
    isCtaDisabled: true,
    isInputDisabled: false,
    className: 'cart-order-summary__promo-input _is-error',
  },
  [PROMO_STATUSES.PROMO_APPLIED]: {
    code: PROMO_STATUSES.PROMO_APPLIED,
    showPromoInput: false,
  },
};
