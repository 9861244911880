import { ajaxRequest } from 'app/utils/helpers';
import { dlTrackEmptyBagGlobalMessage } from 'app/analytics/globalMessagesAnalytics';
import { showWarning } from 'app/globalMessages/globalMessagesClient';
import { LOWEST_MESSAGE_PRIORITY } from 'app/globalMessages/globalMessagesConstants';
import { EMPTY_CART } from './emptyCartConstants';

const { emptyCartUrl } = window.inlineCartConfiguration;

const emptyCart = template => ({
  type: EMPTY_CART,
  template,
});

export const fetchEmptyCart = () => dispatch =>
  ajaxRequest('GET', emptyCartUrl).then(html => {
    dispatch(emptyCart(html || ''));
    showWarning({
      messageId: 'order.items.empty.cart.alert.message',
      sticky: true,
      priority: LOWEST_MESSAGE_PRIORITY,
    });
    dlTrackEmptyBagGlobalMessage();
  });
