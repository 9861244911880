import { useEffect } from 'react';
import { getGlobalMessagesRoot } from '../../../../globalMessages/globalMessagesUtils';
import { SHOW_MESSAGE_EVENT } from '../../../../globalMessages/globalMessagesConstants';
import { showSuccess } from '../../../../globalMessages/globalMessagesClient';

const restrictedPaths = ['login', 'checkout', '/review', '/order-confirmation'];
const currentPath = window.location.pathname;

const isPathNotRestricted = !restrictedPaths.some(path => currentPath.split('/').includes(path));

export const useExchangeMessage = ({ miniCart, messageDescriptor }) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const showBanner = () => {
    if (isPathNotRestricted && miniCart.exchange?.amount) {
      document.querySelector('.header__promo-wrapper').style.display = 'none';
      showSuccess(messageDescriptor);
    }
  };

  useEffect(() => {
    const globalMessagesRoot = getGlobalMessagesRoot();

    showBanner();

    const handleShowMessage = event => {
      if (event.detail.displayTime >= 0) {
        setTimeout(() => {
          showBanner();
        }, event.detail.displayTime * 1000);
      } else {
        showBanner();
      }
    };

    if (globalMessagesRoot) {
      globalMessagesRoot.addEventListener(SHOW_MESSAGE_EVENT, handleShowMessage);
    } else {
      console.error('GlobalMessagesRoot not found');
    }

    return () => {
      if (globalMessagesRoot) {
        globalMessagesRoot.removeEventListener(SHOW_MESSAGE_EVENT, handleShowMessage);
      }
    };
  }, [showBanner, miniCart, messageDescriptor]);
};
