import { RECEIVE_MINI_CART, MINI_CART_IS_FETCHING } from './miniCartConstants';

export const miniCart = (
  state = {
    id: null,
    entries: [],
    exchange: {},
    subtotal: '',
    totalPrice: '',
    totalPriceWithoutTax: '',
    totalTax: '',
    totalItems: 0,
    deliveryCost: '',
    totalDiscounts: '',
    subTotalWithoutDiscounts: '',
    isMiniCartFetching: false,
  },
  action
) => {
  switch (action.type) {
    case MINI_CART_IS_FETCHING:
      return {
        ...state,
        isMiniCartFetching: true,
      };
    case RECEIVE_MINI_CART:
      return {
        ...state,
        isMiniCartFetching: false,
        id: action.id,
        entries: action.entries,
        exchange: action.exchange,
        subtotal: action.subtotal,
        totalPrice: action.totalPrice,
        totalPriceWithoutTax: action.totalPriceWithoutTax,
        totalTax: action.totalTax,
        totalItems: action.totalItems,
        deliveryCost: action.deliveryCost,
        totalDiscounts: action.totalDiscounts,
        subTotalWithoutDiscounts: action.subTotalWithoutDiscounts,
      };
    default:
      return state;
  }
};
