import React, { useState, useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import TrustPilotReviewCard from './trustPilotReviewCard';
import { getStarLink, round } from './utils';
import './trustpilot-reviews.scss';

const TrustPilotCarousel = ({ reviews, totalReviews, trustpilotScore }) => {
  const { humanReadableRating } = round(trustpilotScore);
  const [isHovered, setIsHovered] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const carouselRef = useRef(null);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = event => {
    event.preventDefault();
    setIsHovered(false);
  };

  const handleMouseDown = e => {
    setIsDragging(true);
    setStartX(e.pageX - carouselRef.current.offsetLeft);
    setScrollLeft(carouselRef.current.scrollLeft);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseMove = e => {
    if (!isDragging) return;
    const x = e.pageX - carouselRef.current.offsetLeft;
    const walk = (x - startX) * 2;
    carouselRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleKeyDown = e => {
    if (e.key === 'ArrowRight') {
      carouselRef.current.scrollLeft += 30;
    } else if (e.key === 'ArrowLeft') {
      carouselRef.current.scrollLeft -= 30;
    }
  };

  const onTouchEnd = e => {
    e.stopPropagation();
    // TODO refactor this in a more performant way
    setTimeout(() => {
      setIsHovered(false);
    }, 10);
  };

  useEffect(() => {
    const carousel = carouselRef.current;
    const scrollCarousel = () => {
      if (!isHovered && !isDragging) {
        carousel.scrollLeft += 1;
        if (carousel.scrollLeft >= carousel.scrollWidth / 2) {
          carousel.scrollLeft = 0;
        }
      }
    };

    const intervalId = setInterval(scrollCarousel, 10);

    return () => {
      clearInterval(intervalId);
    };
  }, [isHovered, isDragging]);

  return (
    <section className="trustpilot-reviews" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <h2 className="trustpilot-reviews__title">
        <FormattedMessage id={`trustpilot.score.${humanReadableRating}`} /> &nbsp; &nbsp;
        <img src={getStarLink(round(trustpilotScore).rating)} alt="fitflop rating" />
      </h2>
      <a
        className="trustpilot-reviews__subtitle"
        href="https://www.trustpilot.com/review/fitflop.com"
        target="_blank"
        rel="noreferrer"
      >
        <FormattedMessage
          tagName="span"
          id="trustpilot.reviews.subtitle"
          values={{
            b: chunks => <b>{chunks}</b>,
            u: chunks => <u>{chunks}</u>,
            0: trustpilotScore,
            1: totalReviews.toLocaleString(),
          }}
        />
        <img src="/assets/images/thirdParty/trustpilot.svg" height="30" role="presentation" alt="trustpilot" />
      </a>
      <span className="trustpilot-reviews__stars-note">
        <FormattedMessage id="trustpilot.reviews.stars" />
      </span>
      <ul
        className="trustpilot-reviews__review-list"
        ref={carouselRef}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseUp}
        onKeyDown={handleKeyDown}
        onTouchStart={handleMouseEnter}
        onTouchEnd={onTouchEnd}
        role="listbox"
        tabIndex="0"
      >
        {reviews.concat(reviews).map((review, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <TrustPilotReviewCard key={`${review.id}-${index}`} role="option" review={review} />
        ))}
      </ul>
      <a
        className="button trustpilot-reviews__more-reviews no-select"
        href="https://www.trustpilot.com/review/www.fitflop.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FormattedMessage id="trustpilot.reviews.read.more.reviews" />
      </a>
    </section>
  );
};

export default TrustPilotCarousel;
