import React from 'react';
import { connect } from 'react-redux';
import { setGlobalCartInfo } from 'app/utils/globalDataHelpers';
import headerMiniHOC from '../headerMiniHOC';
import MiniCartApp from './miniCartApp';
import { fetchMiniCartOrCart, receiveMiniCart } from './miniCartActions';
import { TRANSFER_MINI_CART_ADD, MINI_CART_ROOT_ELEMENT } from './miniCartConstants';

const { baseCartUrl } = window.inlineCartConfiguration;

const miniCartConfig = WrappedComponent => props => (
  <WrappedComponent
    miniConfig={{
      miniRootElement: MINI_CART_ROOT_ELEMENT,
      transferMiniAdd: TRANSFER_MINI_CART_ADD,
      baseUrl: baseCartUrl,
      pageType: 'bag',
      cmsComponents: {
        shoppingList: document.querySelector('.mini-cart__shopping-list'),
        retrieveBag: document.querySelector('.mini-cart__retrieve-bag'),
      },
      afterFetch: setGlobalCartInfo,
    }}
    {...props}
  />
);

const mapDispatchToProps = dispatch => ({
  fetchMini() {
    return dispatch(fetchMiniCartOrCart());
  },
  updateMini(json) {
    dispatch(receiveMiniCart(json));
  },
});

export default connect(
  null,
  mapDispatchToProps
)(miniCartConfig(headerMiniHOC(MiniCartApp)));
