import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

const miniTypeMap = {
  bag: 'miniCart',
  wishlist: 'miniWishlist',
};

const MiniCartHeader = ({ miniType }) => {
  const { totalItems } = useSelector(state => state[miniTypeMap[miniType]]);

  return (
    <div className="mini__header">
      <FormattedMessage
        id={miniType === 'bag' ? 'cart.page.title' : 'products.wishlist.header'}
        description={miniType === 'bag' ? 'Your bag' : 'Your wishlist'}
      />{' '}
      ({totalItems})
    </div>
  );
};

export default MiniCartHeader;
