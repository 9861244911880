import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { usernamePadding } from 'app/utils/helpers';
import { AccountNameMenu } from './AccountNameMenu';

const { accountLoginUrl } = window.inlineGlobalConfig;

function AccountNameHeader({ accountName, accountIsLogged }) {
  useEffect(() => {
    usernamePadding();
  });

  return (
    <div className="nav__account-label">
      {accountIsLogged ? (
        <AccountNameMenu accountName={accountName} />
      ) : (
        <a href={accountLoginUrl} className="nav__account-label--signin ellipsis">
          <i className="fa-light fa-user nav__btn-icon" />
        </a>
      )}
    </div>
  );
}

AccountNameHeader.defaultProps = {
  accountName: '',
};

AccountNameHeader.propTypes = {
  accountIsLogged: PropTypes.bool.isRequired,
  accountName: PropTypes.string,
};

export default AccountNameHeader;
