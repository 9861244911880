export function bvTrackAccountCreation() {
  if (window.bvCallbackArg) {
    window.bvCallbackArg.pixel.trackConversion({ type: 'AccountCreated' });
  }
}

export function bvTrackSubscriptionSubmit() {
  if (window.bvCallbackArg) {
    window.bvCallbackArg.pixel.trackConversion({ type: 'NewsletterSignup' });
  }
}

export function bvTrackUserLoggingIn() {
  if (window.bvCallbackArg) {
    window.bvCallbackArg.pixel.trackConversion({ type: 'UserLoggedIn' });
  }
}
