import { connect } from 'react-redux';
import MiniCartSummary from './miniCartSummary';

const mapStateToProps = ({ miniCart }) => ({
  subtotal: miniCart.subtotal,
  totalItems: miniCart.totalItems,
  exchange: miniCart.exchange,
});

export default connect(mapStateToProps)(MiniCartSummary);
