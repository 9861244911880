import {
  RECEIVE_AUTOCOMPLETE_SUGGESTIONS,
  UPDATE_SEARCH_QUERY,
  TERM_SECTION,
  PRODUCT_SECTION,
  CATEGORY_SECTION,
  TERM_TYPE,
  PRODUCT_TYPE,
  TRENDING_TYPE,
  POPULAR_PRODUCT_TYPE,
} from './autoSuggestConstants';

const { maxSuggestions } = window.inlineSearchConfiguration || {
  maxSuggestions: 6,
};
const { terms, maxTerms } = window.inlineSearchTerms || {
  terms: [],
  maxTerms: 5,
};
const { mostPopular, maxMostPopular } = window.inlineMostPopular || {
  mostPopular: [],
  maxMostPopular: 6,
};

const { locale } = window.inlineGlobalConfig;

const initialState = [
  {
    section: TERM_SECTION,
    suggestions: terms
      .filter(term => term && term.linkName)
      .slice(0, maxTerms)
      .map(term => ({
        url: `/${locale.country}/${locale.language}${term.url}`,
        linkName: term.linkName,
        id: term.id.toLowerCase(),
        type: TRENDING_TYPE,
      })),
  },
  {
    section: PRODUCT_SECTION,
    suggestions: mostPopular.slice(0, maxMostPopular).map(product => ({
      code: product.id,
      url: `/${locale.country}/${locale.language}${product.url}`,
      thumbnail: product.images.find(p => p.imageType === 'PRIMARY').url,
      name: product.name,
      description: product.description,
      type: POPULAR_PRODUCT_TYPE,
    })),
  },
  {
    section: CATEGORY_SECTION,
    suggestions: [],
  },
];

export const suggestions = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_AUTOCOMPLETE_SUGGESTIONS:
      if (action.suggestions.length <= 0) {
        return initialState;
      }

      return [
        {
          section: TERM_SECTION,
          hasViewMore: false,
          suggestions: action.suggestions.terms.slice(0, maxTerms).map(term => ({ term, type: TERM_TYPE })),
        },
        {
          section: PRODUCT_SECTION,
          hasViewMore: action.suggestions.products.length > maxSuggestions,
          suggestions: action.suggestions.products
            .slice(0, maxSuggestions)
            .map(item => ({ ...item, type: PRODUCT_TYPE })),
        },
      ];
    default:
      return state;
  }
};

export const searchQuery = (state = '', action) => {
  switch (action.type) {
    case UPDATE_SEARCH_QUERY:
      return action.query;
    default:
      return state;
  }
};
