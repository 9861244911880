import React from 'react';
import ReadMore from './readMore';
import { getStarLink, round } from './utils';

const TrustPilotReviewCard = ({ review }) => {
  const { title, customerName, createdAt, stars, text } = review;
  const locale = window.inlineGlobalConfig.locale.isoCode.replace('_', '-');
  const formattedDate = new Date(createdAt).toLocaleDateString(locale);

  return (
    <article className="trustpilot-reviews__review no-select">
      <img className="trustpilot-reviews__review-rating-image" src={getStarLink(round(stars).rating)} alt="rating" />
      <div className="trustpilot-reviews__review-info">
        <address>{customerName}</address>, <time>{formattedDate}</time>
      </div>
      <h3 className="trustpilot-reviews__review-title">{title}</h3>
      <ReadMore>{text}</ReadMore>
    </article>
  );
};

export default TrustPilotReviewCard;
