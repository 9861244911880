import { transitionEndEvent } from 'app/utils/helpers';
import { addStyle, addClass } from 'app/utils/domHelpers';

export const slideProductUp = (productId, rootElement) => json => {
  const TIMEOUT_BEFORE_ADD_CLASS = 50;
  let productElement;
  let productElementHeight;

  try {
    productElement = rootElement.querySelector(`[data-product-colour-size-sku="${productId}"]`);
    productElementHeight = productElement.getBoundingClientRect().height;
    addStyle('max-height', `${productElementHeight}px`, productElement);
    setTimeout(() => {
      addClass('mini__product-wrapper--animate', productElement);
    }, TIMEOUT_BEFORE_ADD_CLASS);
  } catch (err) {
    console.warn('No product element found to slide up');
    return Promise.resolve(json);
  }

  return transitionEndEvent(productElement, json);
};
