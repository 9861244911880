import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { miniCart } from './miniCart/miniCartReducer';
import { miniWishlist } from './miniWishlist/miniWishlistReducer';
import { loggedUserInfo } from '../utils/loggedUserInfoHOC/loggedUserInfoReducer';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // eslint-disable-line no-underscore-dangle
const store = createStore(
  combineReducers({
    loggedUserInfo,
    miniCart,
    miniWishlist,
  }),
  composeEnhancers(applyMiddleware(thunk))
);

export default store;
